import React from 'react';
import './styles.module.css';
import Field from '../Field';
import { Range as ReactRange } from 'react-range';

/**
 * Range component
 * Range input
 * @property {number} min
 * @property {number} max
 * @property {undefined}
 */
export default function Range({ value, onChange, className, ...attrs }) {
  return (
    <Field
      InputElement={ReactRange}
      values={[value]}
      onChange={values => onChange(values[0])}
      className={className || ''}
      renderTrack={({ props, children }) => (
        <div styleName="track" {...props}>
          {children}
        </div>
      )}
      renderThumb={({ props }) => <div styleName="thumb" {...props} />}
      {...attrs}
    />
  );
}
