import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import snarkdown from 'snarkdown';
import { useTranslation } from 'react-i18next';
import './styles.css';
import './styles.module.css';
import moment from 'moment';
import Input from '../Input';
import Switch from '../../Switch';

/**
 * InputDatePicker component
 * InputDatePicker form input
 * @property {string} label
 */
export default function InputDatePicker({
  label = '',
  onChange = () => null,
  value = new Date(),
  className,
  list,
  field,
  keyprops,
  datepicker
}) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [present, setPresent] = useState(false);
  const [year, setYear] = useState(new Date());

  function isValidDate(d) {
    return moment(d, 'MMMM YYYY', true).isValid();
  }

  useEffect(() => {
    if (
      field === 'date' &&
      datepicker === 'duration' &&
      value &&
      !value.includes('-') &&
      !value.includes('–')
    ) {
      setStartDate(new Date(value));
      setEndDate(new Date());
      document.getElementById('endDatePicker').click();
      return;
    }

    if (datepicker === 'duration') {
      let splitDate = [new Date(), new Date()];
      if (value && value.split(/-|–|\//gi).length === 2) {
        splitDate = value.split(/-|–|\//gi).map(date =>
          date.trim() === 'Present' ||
          date.trim() === 'Current' ||
          date
            .trim()
            .toLowerCase()
            .includes('now') ||
          date.trim().length === 0
            ? 'Present'
            : isValidDate(date.trim())
            ? new Date(moment(date.trim(), 'MMMM YYYY').format('YYYY/MM/DD'))
            : new Date()
        );
      }

      setStartDate(
        splitDate && isValidDate(splitDate[0])
          ? splitDate[0]
          : splitDate[0] === 'Present'
          ? new Date()
          : new Date('January 2016')
      );

      setEndDate(
        splitDate && isValidDate(splitDate[1])
          ? splitDate[1]
          : splitDate[1] === 'Present'
          ? new Date()
          : new Date('June 2018')
      );

      setPresent(splitDate[1] === 'Present');
    } else {
      if (value.split(' ').length === 2) {
        const monthYear = moment(value.trim(), 'MMMM YYYY').format(
          'YYYY/MM/DD'
        );
        setYear(
          value && isValidDate(new Date(monthYear))
            ? new Date(monthYear)
            : new Date()
        );
      } else {
        const year = moment(value.trim(), 'YYYY').format('YYYY/MM/DD');
        setYear(
          value && isValidDate(new Date(year)) ? new Date(year) : new Date()
        );
      }
    }
  }, [value]);
  const handleOnChange = (date, type) => {
    if (datepicker === 'duration') {
      const prevValue = index =>
        value ? value.split(/-|–|\//gi)[index] : moment().format('MMMM yyyy');
      const time =
        date === 'Present' ? 'Present' : moment(date).format('MMMM yyyy');
      let result = '';
      if (type === 'start') {
        result = time + ' -' + prevValue(1);
      } else {
        result = prevValue(0) + '- ' + time;
      }
      if (!value && type === 'start') {
        document.getElementById('endDatePicker').click();
      }
      if (!value && type === 'end') {
        document.getElementById('startDatePicker').click();
      }
      onChange(result, field);
    } else if (type === 'date') {
      onChange(moment(date).format('MMMM yyyy'), 'date');
    } else {
      onChange(moment(date).format('yyyy'), 'date');
    }
  };
  return (
    <div className={className || ''}>
      <label styleName="label" htmlFor={`${list}-section-form-${keyprops}`}>
        <span
          dangerouslySetInnerHTML={{
            __html: snarkdown(t(label))
          }}
        />
      </label>
      <div
        styleName="datepicker"
        className={`${datepicker !== 'duration' ? 'fullwidth' : ''} ${
          !value ? ' no-value' : ''
        }`}
      >
        {datepicker === 'duration' ? (
          <>
            <DatePicker
              id="startDatePicker"
              selected={startDate}
              onChange={date => handleOnChange(date, 'start')}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
            />
            <span>-</span>

            <div className="react-datepicker-wrapper-present">
              <DatePicker
                id="endDatePicker"
                renderCustomHeader={({ date, decreaseYear, increaseYear }) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <button
                      type="button"
                      className="react-datepicker__navigation react-datepicker__navigation--previous"
                      aria-label="Previous Year"
                      onClick={() => decreaseYear()}
                      style={{ top: '8px' }}
                    >
                      Previous Year
                    </button>
                    <button
                      type="button"
                      className="react-datepicker__navigation react-datepicker__navigation--next"
                      aria-label="Next Year"
                      onClick={() => increaseYear()}
                      style={{ top: '8px' }}
                    >
                      Next Year
                    </button>
                    <div
                      className="react-datepicker__header react-datepicker-year-header"
                      style={{ margin: '-9px 0' }}
                    >
                      {moment(date).format('YYYY')}
                    </div>
                    <div className="duration-present">
                      <Switch
                        label={t('generator.present')}
                        checked={present}
                        onChange={({ target }) =>
                          handleOnChange(
                            target.checked ? 'Present' : endDate,
                            'end'
                          )
                        }
                        onClick={e => e.stopPropagation()}
                      />
                      <span
                        onClick={() =>
                          handleOnChange(!present ? 'Present' : endDate, 'end')
                        }
                      >
                        {t('generator.present')}
                      </span>
                    </div>
                  </div>
                )}
                selected={endDate}
                onChange={date => handleOnChange(date, 'end')}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                className={present ? 'disabled' : ''}
              />
              <Input
                defaultValue="Present"
                className="react-datepicker-wrapper"
                style={{ position: 'absolute', top: 0, zIndex: -1 }}
              />
            </div>
          </>
        ) : datepicker === 'year' ? (
          <DatePicker
            selected={year}
            onChange={date => handleOnChange(date, 'year')}
            dateFormat="yyyy"
            showYearPicker
          />
        ) : (
          <DatePicker
            selected={year}
            onChange={date => handleOnChange(date, 'date')}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        )}
      </div>
    </div>
  );
}
