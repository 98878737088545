import React, { useEffect } from 'react';
import Field from '../Field';
import './styles.module.css';
import smoothscroll from 'smoothscroll-polyfill';

/**
 * Textarea component
 * A styled and controlled textarea that grows based on input
 * @properties Inherited from Field
 */
export default function Textarea({
  className,
  rows,
  autocompleteGpt = false,
  haveAutocompletion = false,
  ...attrs
}) {
  useEffect(() => {
    const parentEl = document.getElementsByTagName('main')[0].parentElement;
    const els = document.getElementsByTagName('textarea');
    const scrollTo = parentEl.scrollTop;
    for (let i = 0; i < els.length; i += 1) {
      els[i].style.height = 'auto';
      els[i].style.height = `${els[i].scrollHeight + 30}px`;
      const ghost = document.getElementById(els[i].id + '-ghost');
      if (ghost) {
        ghost.style.height = `${els[i].scrollHeight + 3}px`;
      }
    }
    smoothscroll.polyfill();
    parentEl.scrollTo(0, scrollTo);
  });
  return (
    <>
      <Field
        styleName="textarea"
        InputElement="textarea"
        className={className || ''}
        rows={rows || 6}
        autocompleteGpt={autocompleteGpt}
        haveAutocompletion={haveAutocompletion}
        {...attrs}
      />
    </>
  );
}
