import { navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState, useEffect } from 'react';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg';
import Button from '../../components/Button';
import { Input, Checkbox, SelectCreatable } from '../../components/Form';
import { notify } from '../Notification';
import { NOTIFICATIONS } from '../../lib/constants';
import User from '../../stores/User';
import './styles.module.css';
import { useTranslation } from 'react-i18next';
import RmsStore from '../../stores/Rms';
import Loading from '../Loading';
import lsTest from '../../utils/lsAvailable';

/**
 * AuthForm component
 * Authentication form
 * @property {string} type
 * @property {boolean} showGuest
 * @property {string} title
 * @property {string} description
 */
export default observer(AuthForm);
function AuthForm({
  type = '',
  title = '',
  noGuest = false,
  onSuccess = () => null,
  className,
  updateNewPassword,
  ...attrs
}) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    email: '',
    password: '',
    busy: false
  });
  const [isAffiliate, setIsAffiliate] = useState(false);
  const { signup, login, sendOobMail } = useContext(User);
  const CTA = {
    signup: t('auth.btn_signup'),
    login: t('auth.btn_login'),
    reset: t('auth.btn_reset'),
    'reset-password': t('auth.btn_reset')
  };
  const loginOptions = ['department', 'major', 'college'];

  const [newPassword, setNewPassword] = useState('');
  const { store } = useContext(RmsStore);
  const { isRms, rmsElement } = store;
  const updatePassword = e => {
    const { value } = e.target;
    setNewPassword(value);
  };

  function updateForm(e) {
    const { name, value } = e.target;

    setState({ ...state, [name]: value });
  }

  async function socialSignin(e, provider) {
    e.preventDefault();
    setState({ ...state, busy: true });

    try {
      if (type === 'login') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'user-login-submit',
          method: provider
        });
        await login(provider);
        if (mixpanel) {
          mixpanel.track('user-login-submit', {
            method: provider
          });
        }
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'user-signup-submit',
          method: provider
        });
        if (mixpanel) {
          mixpanel.track('user-signup-submit', {
            method: provider
          });
        }
        await signup(provider);
      }
    } catch (err) {
      notify(err && err.message ? err.message : NOTIFICATIONS.error);
      if (type === 'login') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'user-login-fail',
          method: provider === 'email' ? 'onsite' : provider,
          'validation-error': err.code
        });
        if (mixpanel) {
          mixpanel.track('user-login-fail', {
            method: provider === 'email' ? 'onsite' : provider,
            'validation-error': err.code
          });
        }
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'user-signup-fail',
          method: provider === 'email' ? 'onsite' : provider,
          'validation-error': err.code
        });
        if (mixpanel) {
          mixpanel.track('user-signup-fail', {
            method: provider === 'email' ? 'onsite' : provider,
            'validation-error': err.code
          });
        }
      }
      console.warn(err);
    }
    setState({ busy: false });
    onSuccess();
  }

  function handleSubmit(e) {
    e.preventDefault();
    const { email, password } = state;

    (async () => {
      setState({ ...state, busy: true });

      try {
        switch (type) {
          case 'signup':
            if (
              isRms &&
              rmsElement.active &&
              rmsElement.loginCustom &&
              rmsElement.loginCustom.emailRegex &&
              rmsElement.loginCustom.emailRegexError
            ) {
              if (
                !rmsElement.loginCustom.emailRegex.includes(email.split('@')[1])
              ) {
                notify(rmsElement.loginCustom.emailRegexError);
                setState({ ...state, busy: false });
                return;
              }
            }
            if (
              isRms &&
              rmsElement.active &&
              rmsElement.loginCustom &&
              rmsElement.loginCustom.department
            ) {
              if (!state.department) {
                notify('Please select your department');
                setState({ ...state, busy: false });
                return;
              }
            }
            if (
              isRms &&
              rmsElement.active &&
              rmsElement.loginCustom &&
              rmsElement.loginCustom.major
            ) {
              if (!state.major) {
                notify('Please select your major');
                setState({ ...state, busy: false });
                return;
              }
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'user-signup-submit',
              method: 'onsite'
            });
            if (mixpanel) {
              mixpanel.track('user-signup-submit', {
                method: 'onsite'
              });
            }
            const dataForSignup = { ...state };
            delete dataForSignup.busy;

            await signup('email', dataForSignup);

            onSuccess();
            break;
          case 'login':
            if (
              isRms &&
              rmsElement.active &&
              rmsElement.loginCustom &&
              rmsElement.loginCustom.emailRegex &&
              rmsElement.loginCustom.emailRegexError
            ) {
              if (
                !rmsElement.loginCustom.emailRegex.includes(email.split('@')[1])
              ) {
                notify(rmsElement.loginCustom.emailRegexError);
                setState({ ...state, busy: false });
                return;
              }
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'user-login-submit',
              method: 'onsite'
            });
            if (mixpanel) {
              mixpanel.track('user-login-submit', {
                method: 'onsite'
              });
            }
            await login('email', { email, password });
            onSuccess();
            break;
          case 'reset':
            if (!email || email.length < 1) {
              setState({ ...state, busy: false });
              notify(t('auth.email_not_found'));
            } else {
              await sendOobMail(
                email,
                'forget_password',
                isRms ? rmsElement.domain : ''
              ).then(res => {
                setState({ ...state, busy: false });
                if (res.error) {
                  notify(t('auth.failed_send_email'));
                } else {
                  notify(t('auth.check_email'));
                }
              });
            }
            break;
          case 'reset-password':
            await updateNewPassword(newPassword);
            setState({ ...state, busy: false });
            break;
          default:
            throw new Error('Invalid action');
        }
      } catch (err) {
        setState({ ...state, email: '', password: '' });
        notify(
          err.code === 'auth/user-not-found'
            ? t('auth.error_account')
            : err && err.message
            ? err.message
            : NOTIFICATIONS.error
        );
        if (type === 'signup') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'user-signup-fail',
            method: 'onsite',
            'validation-error': err.code
          });
          if (mixpanel) {
            mixpanel.track('user-signup-fail', {
              method: 'onsite',
              'validation-error': err.code
            });
          }
        } else {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'user-login-fail',
            method: 'onsite',
            'validation-error': err.code
          });
          if (mixpanel) {
            mixpanel.track('user-login-fail', {
              method: 'onsite',
              'validation-error': err.code
            });
          }
        }
      }
      setState({ ...state, busy: false });

      if (type === 'reset-password') {
        notify(t('auth.set_new_password'));
      }
    })();
  }

  useEffect(() => {
    let isMountedRef = true;
    if (
      lsTest() === true &&
      (window.localStorage.getItem('affiliate-rezi-id') ||
        window.location.search.indexOf('tid') > -1) &&
      isMountedRef
    ) {
      setIsAffiliate(true);
    }
    return () => (isMountedRef = false);
  }, []);

  function updateOption(data, type) {
    if (data && data.__isNew__) {
      delete data.__isNew__;
    }
    data !== undefined && setState({ ...state, [type]: data.value });
  }

  const optionList = (options, translationIndex) => {
    const list = JSON.parse(options);
    if (list.length > 0) {
      return JSON.parse(options).map(c => {
        if (typeof c === 'object') {
          return {
            label: translationIndex
              ? t(`${translationIndex}.${Object.keys(c)}`)
              : Object.keys(c),
            options: Object.values(c)[0].map(sub => {
              return {
                value: Object.keys(c) + '-' + sub,
                label: translationIndex ? t(`${translationIndex}.${sub}`) : sub
              };
            })
          };
        } else {
          return {
            value: translationIndex ? t(`${translationIndex}.${c}`) : c,
            label: translationIndex ? t(`${translationIndex}.${c}`) : c
          };
        }
      });
    } else {
      return [];
    }
  };

  return (
    <div className={className || ''} {...attrs}>
      {type !== 'signup' ||
      !(
        type === 'signup' &&
        isRms &&
        rmsElement.loginCustom &&
        rmsElement.loginCustom.signupTitle
      ) ? (
        <h1 styleName="heading">{title}</h1>
      ) : (
        <h1 styleName="heading">{rmsElement.loginCustom.signupTitle}</h1>
      )}
      {type === 'signup' &&
        isRms &&
        rmsElement.loginCustom &&
        rmsElement.loginCustom.signupSubTitle && (
          <h3 styleName="subHeading">
            {rmsElement.loginCustom.signupSubTitle}
          </h3>
        )}
      {!(isRms && rmsElement.active && rmsElement.noLoginProvider) && (
        <>
          {type !== 'reset' && type !== 'reset-password' && (
            <div styleName="social">
              <Button
                styleName="social-button"
                theme="secondary"
                busy={state.busy}
                onClick={e => socialSignin(e, 'facebook')}
              >
                <FacebookIcon styleName="social-button-icon" /> Facebook
              </Button>
              <Button
                styleName="social-button"
                theme="secondary"
                busy={state.busy}
                onClick={e => socialSignin(e, 'google')}
              >
                <GoogleIcon styleName="social-button-icon" /> Google
              </Button>
            </div>
          )}
        </>
      )}
      {(isRms && rmsElement.active && rmsElement.noLoginProvider) ||
      type === 'reset' ||
      type === 'reset-password' ? (
        <p
          style={{ borderBottom: '1px solid rgb(223, 224, 229)', margin: '0' }}
        />
      ) : (
        <p styleName="or_line_wrap"> OR</p>
      )}

      <form
        styleName="form"
        data-form-action={type}
        className={type === 'login' ? 'log-in-page' : 'sign-up-page'}
        id={type === 'login' ? 'log-in-email' : 'sign-up-email'}
      >
        {type === 'signup' && (
          <>
            {isRms &&
              rmsElement.active &&
              rmsElement.loginCustom &&
              ((rmsElement.loginCustom.firstName &&
                rmsElement.loginCustom.lastName) ||
                rmsElement.loginCustom.fullName) && (
                <div styleName="name">
                  {rmsElement.loginCustom.firstName && (
                    <Input
                      styleName="form-input"
                      type="text"
                      name="firstName"
                      autoComplete="firstName"
                      placeholder={t('auth.firstName')}
                      value={state.firstName}
                      onChange={updateForm}
                      required
                    />
                  )}
                  {rmsElement.loginCustom.lastName && (
                    <Input
                      styleName="form-input"
                      type="text"
                      name="lastName"
                      autoComplete="lastName"
                      placeholder={t('auth.lastName')}
                      value={state.lastName}
                      onChange={updateForm}
                      required
                    />
                  )}
                  {rmsElement.loginCustom.fullName && (
                    <Input
                      styleName="form-input"
                      type="text"
                      name="fullName"
                      autoComplete="fullName"
                      placeholder={t('auth.fullName')}
                      value={state.fullName}
                      onChange={updateForm}
                      required
                    />
                  )}
                </div>
              )}
          </>
        )}
        {type !== 'reset-password' && (
          <Input
            styleName="form-input"
            type="email"
            name="email"
            autoComplete="email"
            placeholder={
              isRms &&
              rmsElement.active &&
              rmsElement.loginCustom &&
              rmsElement.loginCustom.labelEmail
                ? rmsElement.loginCustom.labelEmail
                : t('auth.email')
            }
            value={state.email}
            onChange={updateForm}
            required
          />
        )}
        {type === 'signup' &&
          isRms &&
          rmsElement.active &&
          rmsElement.loginCustom && (
            <>
              {rmsElement.loginCustom.phone && (
                <Input
                  styleName="form-input"
                  type="phone"
                  name="phone"
                  autoComplete="phone"
                  placeholder={t('auth.phone')}
                  value={state.phone}
                  onChange={updateForm}
                  required
                />
              )}
              {loginOptions.map(option => (
                <>
                  {rmsElement.loginCustom[option] &&
                    rmsElement.loginCustom[`${option}Options`] && (
                      <SelectCreatable
                        className={`option-section`}
                        id={`${option}-section-form`}
                        placeholder={
                          rmsElement.loginCustom[`${option}Label`] ||
                          t(`auth.${option}`)
                        }
                        onChange={e => updateOption(e, option)}
                        options={optionList(
                          rmsElement.loginCustom[`${option}Options`],
                          rmsElement.loginCustom[`${option}IndexTranslate`] ||
                            null
                        )}
                        required
                      />
                    )}
                </>
              ))}

              {rmsElement.loginCustom.studentID &&
                rmsElement.loginCustom.studentIDLabel &&
                rmsElement.loginCustom.studentIDRegex && (
                  <Input
                    styleName="form-input"
                    type="text"
                    name="studentId"
                    autoComplete="studentId"
                    placeholder={rmsElement.loginCustom.studentIDLabel}
                    value={state.studentId}
                    onChange={updateForm}
                    pattern={rmsElement.loginCustom.studentIDRegex}
                    required
                  />
                )}
            </>
          )}
        {type !== 'reset' && type !== 'reset-password' && (
          <Input
            styleName="form-input"
            type="password"
            name="password"
            autoComplete="current-password"
            placeholder={t('auth.password')}
            value={state.password}
            onChange={updateForm}
            required
          />
        )}
        {type === 'reset-password' && (
          <Input
            styleName="form-input"
            type="password"
            name="password"
            autoComplete="current-password"
            placeholder={t('auth.set_new_password')}
            value={newPassword}
            onChange={updatePassword}
            required
          />
        )}
        {type === 'login' && (
          <span styleName="passwordReset" onClick={() => navigate('/reset')}>
            {t('auth.forget_pass')}
          </span>
        )}
        <Button
          styleName="form-submit"
          type="submit"
          size="large"
          onClick={e => handleSubmit(e)}
          busy={state.busy}
        >
          {CTA[type]}
          {state.busy && (
            <Loading
              minHeight="0"
              color="#cecece"
              style={{ top: '14px' }}
              stylename="small float-left"
            />
          )}
        </Button>
      </form>
    </div>
  );
}
