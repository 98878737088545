import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import React, { useEffect, useState } from 'react';
import './styles.module.css';

const NOTIFICATION_EVENT = 'app-notification';

export function notify(message) {
  window.dispatchEvent(
    new CustomEvent(NOTIFICATION_EVENT, { detail: { message } })
  );
}

/**
 * Notification component
 * Global event-triggered notification center
 * @property {number} duration Duration to keep notification on screen
 */
export default function Notification({ duration = 3000 }) {
  const [state, setState] = useState({
    open: false,
    message: ''
  });

  function reset() {
    setState({
      ...state,
      open: false
    });
  }
  const handleChangeState = e => {
    setState({ message: e.detail.message, open: true });
  };
  useEffect(() => {
    window.addEventListener(NOTIFICATION_EVENT, e => handleChangeState(e));
    return () => {
      window.removeEventListener(NOTIFICATION_EVENT, handleChangeState);
    };
  }, []);

  return (
    <Snackbar
      style={{ zIndex: '999999999' }}
      open={state.open}
      TransitionComponent={Slide}
      onClose={reset}
      autoHideDuration={duration}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      message={<div styleName="notification">{state.message}</div>}
    />
  );
}
