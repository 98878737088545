import React, { Component } from 'react';
import Field from '../Field';

/**
 * Input component
 * Styled and controlled form input
 * @properties Inherited from Field
 */
export default function Input({ className, inputClassName, ...attrs }) {
  return <Field InputElement="input" inputClassName={inputClassName || ''} className={className || ''} {...attrs} />;
}
