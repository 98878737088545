import React from 'react';
import {
  setWithExpiry,
  getWithExpiry
} from '../../utils/compareSemanticVersion';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    const chunkFailedMessageRegex = new RegExp(/Loading chunk [\d]+ failed/);
    const chunkCssFailedMessageRegex = new RegExp(
      /Loading CSS chunk [\d]+ failed/
    );
    const chunckMIMEtypeError = new RegExp(/^Refused to execute script from/);
    const chunckCssMIMEtypeError = new RegExp(/^Refused to apply style from/);

    if (
      chunkFailedMessageRegex.test(error.message) ||
      chunckMIMEtypeError.test(error.message) ||
      chunkCssFailedMessageRegex.test(error.message) ||
      chunckCssMIMEtypeError.test(error.message)
    ) {
      return { hasChunkError: true };
    }
    return { hasCriticalError: true };
  }

  componentDidCatch(error, errorInfo) {
    function lsTest() {
      const test = 'test';
      try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch (e) {
        return false;
      }
    }
    if (this.state.hasChunkError) {
      // This is a chunk error, check if this error is normal or critical
      if (caches) {
        // Browser support caches, we can check chunk existence
        if (window.navigator && window.navigator.onLine) {
          if (lsTest() === true) {
            if (!getWithExpiry('chunk_failed')) {
              if (localStorage && !localStorage.getItem('chunk_failed')) {
                setWithExpiry('chunk_failed', 'true', 10000);
              }
              // user is online so missing chunk come from new version
              caches.open('v1').then(function(cache) {
                cache.keys().then(function(keys) {
                  keys.forEach(function(request, index, array) {
                    cache.delete(request);
                  });
                });
              });
              // delete browser cache and hard reload
              window.location.reload(true);
            } else {
              this.setState({ hasError: true });
            }
          } else {
            alert('Please allow third-party cookie in your browser settings');
            window.location.reload(true);
          }
        } else {
          this.setState({ offline: true });
        }
      } else {
        // if caches is not defined (not supported browser), then error is critical but we do not need to log to sentry
        this.setState({ hasCriticalError: true });
      }
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            margin: 'calc(50vh - 100px) auto',
            maxWidth: '400px'
          }}
        >
          <h1 style={{ textAlign: 'center' }}>Something went wrong</h1>
          <p>
            Can you try to reload the page or clean cache of your browser? If
            it's happen again, please contact us by the chat in the bottom right
            corner
          </p>
          <img
            style={{
              width: '100px',
              position: 'fixed',
              top: '2em',
              left: '2em'
            }}
            src="https://firebasestorage.googleapis.com/v0/b/rezi-3f268.appspot.com/o/images%2Frezi.png?alt=media&token=7145de5a-2d3e-4588-b443-a0845ddf60aa"
          />
        </div>
      );
    }
    return this.props.children;
  }
}
