import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import './styles.module.css';
import { ReactComponent as CloseIcon } from '../../assets/icons/clear.svg';
import StoreRms from '../../stores/Rms';
/**
 * Modal component
 * Modal UI component
 * @property {boolean} open Whether modal is open
 * @property {function} onClose Callback when modal requests closing
 */
export default observer(Modal);
function Modal({
  open = false,
  onClose = () => null,
  children,
  className,
  customOverlayClassName = '',
  clickAway = true,
  modalId,
  ...attrs
}) {
  const { store } = useContext(StoreRms);

  useEffect(() => {
    if (customOverlayClassName && modalId && open) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: customOverlayClassName,
        type: modalId
      });
      if (mixpanel) {
        mixpanel.track(customOverlayClassName, {
          type: modalId
        });
      }
    }
  }, [open]);
  return (
    <ReactModal
      styleName="modal"
      overlayClassName={{
        base: `modal-overlay ${customOverlayClassName}`,
        afterOpen: 'open',
        beforeClose: 'closing'
      }}
      isOpen={open}
      id={modalId}
      onRequestClose={onClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={clickAway}
      closeTimeoutMS={500}
      ariaHideApp={false}
      className={className || ''}
      style={
        store && store.rmsHasInfos
          ? {
              content: {
                '--color-primary': store.rmsElement.color
                  ? store.rmsElement.color
                  : '',
                '--color-violet': store.rmsElement.color
                  ? store.rmsElement.color
                  : '',
                '--color-hover-icon': store.rmsElement.hoverIcon
                  ? store.rmsElement.hoverIcon
                  : '',
                '--color-nav': store.rmsElement.nav ? store.rmsElement.nav : '',
                '--color-nav-hover': store.rmsElement.hoverNav
                  ? store.rmsElement.hoverNav
                  : '',
                '--color-button-created-text':
                  store.rmsElement.buttonCreated &&
                  store.rmsElement.buttonCreated.text
                    ? store.rmsElement.buttonCreated.text
                    : '',
                '--color-button-created-background':
                  store.rmsElement.buttonCreated &&
                  store.rmsElement.buttonCreated.background
                    ? store.rmsElement.buttonCreated.background
                    : ''
              }
            }
          : {
              content: {
                '--color-primary': '#4d70eb'
              }
            }
      }
      {...attrs}
    >
      <CloseIcon styleName="modal-close" onClick={onClose} />
      {children}
    </ReactModal>
  );
}
