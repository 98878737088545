import React from 'react';
import './styles.module.css';

/**
 * Switch component
 * Toggle switch component
 * @property {boolean} checked Whether the switch is active
 * @property {string} size The size of the switch
 * @property {function} onChange Change handler callback, passed to checkbox input
 */
export default function Switch({
  checked = false,
  size = '1rem',
  onChange = () => null,
  className,
  id = '',
  label = '',
  ...attrs
}) {
  return (
    <label
      styleName="switch"
      style={{ fontSize: size }}
      data-checked={checked}
      className={className || ''}
      {...attrs}
    >
      <span className="sr-only">{label}</span>
      <input
        styleName="input"
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <div styleName="thumb" />
    </label>
  );
}
