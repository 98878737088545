import React, { useContext, useEffect, useState } from 'react';
import GuestSignup from '../../components/GuestSignup';
import Billing from '../../stores/Billing';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import RmsStore from '../../stores/Rms';
import PlanModal from '../../components/Checkout/PlanModal';
import { fetching } from '../../apis/functions';
import { auth } from '../../lib/firebase';
import User from '../../stores/User';
import Resumes from '../../stores/Resumes';
import CoverLetters from '../../stores/CoverLetters';
import { trackingPurchase } from '../../utils/tracking';
import moment from 'moment';
import Orders from '../../stores/Orders';
import lsTest from '../../utils/lsAvailable';
/**
 * Upgrade component
 * HOC upgrade modal and guest signup
 */
export default observer(Upgrade);
function Upgrade() {
  const billing = useContext(Billing);
  const { user } = useContext(User);
  const { t } = useTranslation(['translation', 'rms']);
  const [isSuccess, setIsSuccess] = useState(false);
  const { resumes, updateResume } = useContext(Resumes);
  const { coverLetters, updateCoverLetter } = useContext(CoverLetters);
  const { _orderDoc } = useContext(Orders);
  const { store } = useContext(RmsStore);
  const rmsPrefix = store.isRms ? 'rms:' : '';
  const translate = {
    ai: rmsPrefix + 'ai_writer.cta_guest',
    coverLetter: 'sidebar.cta_guest_cover',
    accessCover: 'sidebar.cta_guest_cover',
    profile: 'sidebar.cta_guest_profile',
    resume: 'sidebar.cta_guest_resume',
    resumes: 'sidebar.cta_guest_resume',
    cta: 'sidebar.cta_guest_account',
    keyword: 'sidebar.cta_keyword'
  };

  const displaySuccess = async (
    payment_intent,
    payment_intent_client_secret,
    redirect_status,
    step
  ) => {
    setIsSuccess(true);
    let newUrl = window.location.search.replace('step=' + step + '&', '');
    newUrl = newUrl.replace('payment_intent=' + payment_intent + '&', '');
    newUrl = newUrl.replace(
      'payment_intent_client_secret=' + payment_intent_client_secret + '&',
      ''
    );
    newUrl = newUrl.replace('redirect_status=' + redirect_status, '');
    window.history.pushState(null, null, newUrl);
    billing.openUpgradeModal = true;
    let paymentInfos =
      lsTest() === true ? window.localStorage.getItem('rezi-purchase') : null;
    if (paymentInfos && paymentInfos.length > 0) {
      trackingPurchase({
        ...JSON.parse(paymentInfos),
        id: moment().format('x')
      });
      paymentInfos = JSON.parse(paymentInfos);
      if (paymentInfos.parentResumeId && paymentInfos.pendingOrderId) {
        updateResume(paymentInfos.parentResumeId, {
          resumeReviewOrderId: paymentInfos.pendingOrderId
        });
      }
      if (
        paymentInfos.pendingOrderId &&
        newUrl.includes(paymentInfos.pendingOrderId)
      ) {
        //todo update order status
        _orderDoc(paymentInfos.pendingOrderId)
          .fetch()
          .then(order => {
            order.update({ status: 'paid' });
          });
      }
      if (lsTest() === true) window.localStorage.removeItem('rezi-purchase');
    }
  };
  useEffect(() => {
    const step = new URLSearchParams(window.location.search).get('step');
    const payment_intent = new URLSearchParams(window.location.search).get(
      'payment_intent'
    );
    const payment_intent_client_secret = new URLSearchParams(
      window.location.search
    ).get('payment_intent_client_secret');
    const redirect_status = new URLSearchParams(window.location.search).get(
      'redirect_status'
    );
    const needOpenCheckout =
      window.location.search.indexOf('open-modal=true') > -1 ||
      (lsTest() === true &&
        window.localStorage.getItem('rezi-open-modal') === 'true');
    if (redirect_status === 'succeeded' && user.id && auth.currentUser) {
      if (payment_intent && payment_intent.length > 0) {
        fetching(
          { payment_intent, user: user.id },
          'stripe-retrievePaymentIntents'
        ).then(res => {
          if (
            res &&
            res.status === 'succeeded' &&
            moment(res.created, 'X')
              .add(2, 'm')
              .isAfter(moment())
          ) {
            displaySuccess(
              payment_intent,
              payment_intent_client_secret,
              redirect_status,
              step
            );
          }
        });
      } else {
        displaySuccess(
          payment_intent,
          payment_intent_client_secret,
          redirect_status,
          step
        );
      }
    } else if (needOpenCheckout) {
      if (user.id) {
        billing.isForced = true;
        billing.openUpgradeModal = true;
        if (lsTest() === true)
          window.localStorage.removeItem('rezi-open-modal');
      } else {
        if (lsTest() === true)
          window.localStorage.setItem('rezi-open-modal', 'true');
      }
      const newUrl = window.location.search.replace('open-modal=true', '');
      window.history.pushState(null, null, newUrl);
    }
  }, [user.id]);

  useEffect(() => {
    if (isSuccess) {
      if (resumes.docs.length > 0) {
        resumes.docs.map(({ id: resume }, i) => {
          return updateResume(resume, { locked: false });
        });
      }
      if (coverLetters.docs.length > 0) {
        coverLetters.docs.map(({ id: coverletter }, i) => {
          return updateCoverLetter(coverletter, { locked: false });
        });
      }
    }
  }, [isSuccess, resumes.docs.length, coverLetters.docs.length]);

  return (
    <>
      {billing.openUpgradeModal && (
        <PlanModal
          open={billing.openUpgradeModal}
          onClose={() => {
            billing.closeModal();
            setIsSuccess(false);
          }}
          planId={billing.forceProduct || 'prod_Gkv3mbJHn5bWLs'}
          billed={billing.forceBilling || 'Quarterly'}
          highlight={billing.highlight || null}
          forceCredit={billing.highlight === 'credits'}
          isForced={billing.isForced}
          affiliate={billing.partner}
          resumeReview={billing.resumeReview || null}
          success={isSuccess}
        />
      )}
      {billing.openGuestModal && (
        <GuestSignup
          open={billing.openGuestModal}
          title={
            translate[billing.highlight] ? t(translate[billing.highlight]) : ''
          }
          onClose={() => billing.closeModal()}
        />
      )}
    </>
  );
}
