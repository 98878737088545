import React, { useState, useContext, useEffect } from 'react';
import './styles.module.css';
import { useTranslation } from 'react-i18next';
import Billing from '../../../stores/Billing';
import Radio from '../../Form/Radio';
import PriceDisplay from '../PriceDisplay';
import { trackingProductEvent } from '../../../utils/tracking';
import { observer } from 'mobx-react-lite';

/**
 * PlanModalRow component
 */
export default observer(PlanModalRow);
function PlanModalRow({
  plan,
  forceDetailOpen = '',
  planCheck,
  highlight,
  setPlanCheck,
  setAddCredit = () => {},
  addCredit = false,
  credits = false,
  coupon,
  billed
}) {
  const { t } = useTranslation();
  const billing = useContext(Billing);
  const plansInfo = billing.plans;
  const [hoverFeature, setHoverFeature] = useState('');
  const [detailOpen, setDetailOpen] = useState(
    forceDetailOpen ? forceDetailOpen : ''
  );
  const planJson = require('../price.json');
  const get = planName => planJson.priceTable[billed][planName];
  const details = planId => plansInfo.find(product => product.id === planId);
  const info = credits ? credits : get(plan);
  const detailsTranslate = [
    'resumes',
    'coverLetter',
    'download',
    'credits',
    'ai_resume',
    'ai_coverLetter',
    'review',
    'keyword',
    'analyse',
    'proSample',
    'unlock_templates',
    'picture',
    'docx'
  ];

  const handleOpenDetail = () => {
    if (credits) {
      setDetailOpen('credit' === detailOpen ? '' : 'credit');
    } else {
      setDetailOpen(info.label === detailOpen ? '' : info.label);
    }
    trackingProductEvent(
      (credits
      ? 'credit' === detailOpen
      : info.label === detailOpen)
        ? 'detailCheckoutClose'
        : 'detailCheckoutOpen',
      {
        detail: credits ? 'credit' : info.label
      }
    );
  };

  useEffect(() => {
    setHoverFeature(billing.hoverFeature);
  }, [billing.hoverFeature]);

  useEffect(() => {
    setDetailOpen(forceDetailOpen);
  }, [forceDetailOpen]);

  return (
    <>
      <div styleName="plan-detail-wrapper">
        <div styleName="header-detail">
          <div styleName="radio-wrapper">
            <Radio
              value={credits ? addCredit : planCheck === info.label}
              onChange={() =>
                credits
                  ? setAddCredit(!addCredit)
                  : setPlanCheck(planCheck === info.label ? 'Free' : info.label)
              }
            />
          </div>
          <div styleName="details">
            <div styleName="header" onClick={handleOpenDetail}>
              <i
                className={`fas fa-caret-down ${
                  detailOpen === info.label ? 'open' : ''
                }`}
              />
              <span>
                {credits ? t('modal_plan_payment.50k_credits') : info.label}{' '}
                {info.duration === 'forever' ? 'Plan' : ''}
              </span>
            </div>
          </div>
          <div styleName="detail-price-wrapper">
            <PriceDisplay
              credits={credits}
              plan={credits ? null : plan}
              coupon={coupon}
              billed={billed}
            />
          </div>
        </div>
        <div styleName="details-list" data-open={detailOpen === info.label}>
          <ul>
            {credits ? (
              <li>
                <i
                  className="fas fa-info-circle"
                  style={{ color: 'var(--color-grey-300)' }}
                />
                <span>{t('modal_plan_payment.50k_credits_details')}</span>
              </li>
            ) : (
              detailsTranslate.map(feat => (
                <li
                  styleName={`${highlight === feat ? 'highlight' : ''}`}
                  className={hoverFeature === feat ? 'highlight-hover' : ''}
                  onMouseOver={() => {
                    billing.handleHoverFeature(feat);
                  }}
                  onMouseLeave={() => {
                    billing.handleHoverFeature(
                      billing.hoverFeature === feat ? '' : billing.hoverFeature
                    );
                  }}
                  style={
                    t(
                      `modal_plan_payment.${feat +
                        '_' +
                        details(get(plan).value).metadata[feat]}`
                    ) === '' ||
                    (feat === 'ai_coverLetter' &&
                      details(get(plan).value).metadata.coverLetter === '0')
                      ? { display: 'none' }
                      : {}
                  }
                  key={feat}
                >
                  <i className="fas fa-check" />
                  {t(
                    `modal_plan_payment.${feat +
                      '_' +
                      details(get(plan).value).metadata[feat]}`
                  )}
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
