import React, { useContext, useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import './styles.module.css';
import Loading from '../../Loading';
import Billing from '../../../stores/Billing';
import Orders from '../../../stores/Orders';
import Resumes from '../../../stores/Resumes';
import { trackingSendgrid } from '../../../utils/tracking';
import lsTest from '../../../utils/lsAvailable';

export default function CheckoutForm({ purchaseDetail }) {
  const { _orderDoc } = useContext(Orders);
  const { deleteResume } = useContext(Resumes);
  const stripe = useStripe();
  const elements = useElements();
  const billing = useContext(Billing);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reziPurchase, setReziPurchase] = useState({});

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          const data = {
            plan_id: purchaseDetail.productId && purchaseDetail.productId,
            plan_name: purchaseDetail.productName && purchaseDetail.productName
          };

          trackingSendgrid('update_contact', data);
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          handleFailedReviewOrder();
          if (mixpanel) {
            mixpanel.track('purchase-error', {
              error: 'payment not finished'
            });
          }
          break;
        default:
          handleFailedReviewOrder();
          setMessage('Something went wrong.');
          if (mixpanel) {
            mixpanel.track('purchase-error', {
              error: 'payment went wrong'
            });
          }
          break;
      }
    });
  }, [stripe]);

  const handleFailedReviewOrder = () => {
    const infos =
      lsTest() === true ? window.localStorage.getItem('rezi-purchase') : null;
    if (billing.resumeReview && infos && infos.length > 0) {
      const parsedInfos = JSON.parse(infos);
      if (parsedInfos.pendingOrderId && parsedInfos.resumeReviewId) {
        _orderDoc(parsedInfos.pendingOrderId)
          .fetch()
          .then(order => {
            order.update({ status: 'error' });
          });
        deleteResume(parsedInfos.resumeReviewId);
        if (lsTest() === true) {
          window.localStorage.removeItem('rezi-purchase');
        }
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    let orderId = null;
    if (billing.resumeReview && billing.resumeReview.onInit) {
      //Build resume review for Rms before payment succeed.
      await billing.resumeReview.onInit().then(res => {
        if (res.message === 'success') {
          orderId = res.docRef;
          if (lsTest() === true) {
            window.localStorage.setItem(
              'rezi-purchase',
              JSON.stringify({
                ...purchaseDetail,
                pendingOrderId: res.docRef,
                parentResumeId: res.resumeId,
                resumeReviewId: res.resumeReviewId
              })
            );
          }
        }
      });
    } else {
      if (lsTest() === true)
        window.localStorage.setItem(
          'rezi-purchase',
          JSON.stringify({ ...purchaseDetail })
        );
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          window.location.href +
          '?step=payment-success' +
          (orderId ? '&oid=' + orderId : '')
      }
    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
      handleFailedReviewOrder();
      if (mixpanel) {
        mixpanel.track('purchase-error', {
          error: error.type
        });
      }
    } else {
      setMessage('An unexpected error occured.');
      handleFailedReviewOrder();
      if (mixpanel) {
        mixpanel.track('purchase-error', {
          error: 'unexpected error'
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <div styleName="payment-wrapper">
      <form onSubmit={handleSubmit}>
        <PaymentElement styleName="payment-element" />
        <button
          disabled={isLoading || !stripe || !elements}
          style={{ minHeight: '42px' }}
          id="submit"
        >
          <span style={{ top: '0px', minHeight: '0px', position: 'relative' }}>
            {isLoading ? (
              <Loading
                minHeight="0"
                color="#fff"
                style={{ top: '-10px', right: '0' }}
                stylename="small"
              />
            ) : (
              'Pay now'
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div styleName="payment-message">{message}</div>}
      </form>
    </div>
  );
}
