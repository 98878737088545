import { Link } from '@reach/router';
import React from 'react';

function isInternal(url) {
  const tester = document.createElement('a');

  tester.href = url;
  return tester.hostname === window.location.hostname;
}

/**
 * AdaptiveLink component
 * Link element that adapts to internal and external hrefs
 * @property {string} href Href of the internal link
 * @property {boolean} greedyActive Whether active state should be recursive
 */
export default function AdaptiveLink({
  href = '',
  greedyActive = true,
  children,
  className,
  ...attrs
}) {
  const LinkElement = isInternal(href) ? Link : 'a',
    getActiveState = ({ isCurrent, isPartiallyCurrent, href }) => {
      const check = greedyActive ? isPartiallyCurrent : isCurrent;

      return { active: check.toString() };
    };
  return (
    <LinkElement
      {...(isInternal(href) ? { to: href } : { href })}
      {...(isInternal(href) && { getProps: getActiveState })}
      target={!isInternal(href) ? '_blank' : ''}
      className={className || ''}
      {...attrs}
    >
      {children}
    </LinkElement>
  );
}
