import { Collection, Document } from 'firestorter';
import { createContext } from 'react';
import { struct } from 'superstruct';
import 'whatwg-fetch';
import { auth } from '../lib/firebase';
import { observable } from 'mobx';
import { onAuthStateChanged } from 'firebase/auth';

export const RmsSchema = struct({
  active: 'boolean?',
  aside: 'object?',
  logo: 'object?',
  color: 'string?',
  id: 'string?',
  title: 'string?',
  domain: 'string?',
  fullDomain: 'string?',
  nav: 'string?',
  hoverNav: 'string?',
  proPriceColor: 'string?',
  proPriceLink: 'string?',
  buttonCreated: 'object?',
  allowPromotion: 'boolean?',
  hoverIcon: 'string?',
  asideInvertedTexture: 'boolean?',
  user: 'string? | array?',
  updatedAt: 'string?',
  createdAt: 'string?',
  previewSince: 'string?',
  contact: 'string?',
  domainActive: 'boolean?',
  moderatedAt: 'string?',
  grfId: 'string?',
  grfToken: 'string?',
  //new
  advancedShow: 'boolean?',
  noWritingService: 'boolean?',
  noLoginProvider: 'boolean?',
  noLoginGuest: 'boolean?',
  noBillingMenu: 'boolean?',
  noAIWriter: 'boolean?',
  loginCustom: 'object?',
  reviewCustom: 'boolean? | object?',
  memberManagement: 'boolean?',
  noPromotion: 'boolean?',
  hideReziSamples: 'boolean?',
  showReziSampleList: 'array?',
  forceCV: 'boolean?',
  generatorCustom: 'object?',
  videos: 'object?',
  fullDomainUrl: 'string?',
  transactionalEmailSender: 'string?',
  displayChat: 'boolean?',
  networkOption: 'boolean?',
  hideSubsPlan: 'boolean?',
  dynamicPromotion: 'object?',
  hideSubscription: 'boolean?',
  customTokenRedirect: 'string?',
  customSessionExpired: 'object?',
  revenueShare: 'object?',
  logoUrl: 'string?',
  hideDocument: 'object?',
  hideDom: 'object?',
  powerBy: 'boolean?',
  customUpgradeContent: 'string?'
});

class Rms extends Document {
  constructor(source, options = {}) {
    super(source, {
      schema: RmsSchema,
      ...options
    });
  }
}

function isRms() {
  const domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
  const fullDomain = domain !== 'app.rezi.io' || domain !== 'app.rezi.ai';
  return !fullDomain && !domain.includes('localhost');
}

class Rmss {
  constructor() {
    onAuthStateChanged(auth, user => {
      this.rms.query = ref => {
        return user ? ref.where('user', 'array-contains', user.uid) : null;
      };
    });
  }
  store = observable({
    isRms: null,
    loadRms: isRms(),
    rmsElement: {},
    rmsIndex: 0,
    rmsHasInfos:
      this.isRms &&
      this.rmsElement.active &&
      this.rmsElement.domain &&
      this.rmsElement.title,
    rmsTitle: 'Resume Builder',
    handleRmsTitle(title) {
      this.rmsTitle = title;
    },
    handleRmsElement(elements) {
      this.rmsElement = elements;
      this.rmsTitle = elements.title;
      this.rmsHasInfos =
        this.isRms &&
        this.rmsElement.active &&
        this.rmsElement.domain &&
        this.rmsElement.title;
    },
    handleRms(forceRms) {
      this.isRms = forceRms ? true : isRms();
      this.rmsHasInfos =
        this.isRms &&
        this.rmsElement.active &&
        this.rmsElement.domain &&
        this.rmsElement.title;
    },
    handleLoadRms(status) {
      this.loadRms = status;
    }
  });

  rms = new Collection('rms', {
    createDocument: (source, options) => new Rms(source, options),
    query: ref => null,
    mode: 'on'
  });

  liveRms = new Collection('rms', {
    createDocument: (source, options) => new Rms(source, options),
    query: ref => null,
    mode: 'on'
  });

  getFullDomainRms = domain => {
    return new Collection('rms', {
      createDocument: (source, options) => new Rms(source, options),
      query: ref =>
        ref.where('active', '==', true).where('fullDomain', '==', domain),
      mode: 'off'
    });
  };
  getLiveDomainRms = domain => {
    return new Collection('rms', {
      createDocument: (source, options) => new Rms(source, options),
      query: ref =>
        ref.where('active', '==', true).where('domain', '==', domain),
      mode: 'off'
    });
  };

  updateLiveRms = async (domain, data) => {
    return await this.getLiveDomainRms(domain)
      .fetch()
      .then(async res => {
        if (res.hasDocs) {
          return res.docs[0].update(data);
        }
      });
  };
  getRms = async (id, domain = null) => {
    if (domain) {
      return await this.getFullDomainRms(domain)
        .fetch()
        .then(async res => {
          if (res.hasDocs) {
            return res;
          } else {
            this.liveRms.query = ref =>
              ref
                .where('domain', '==', id)
                .where('active', '==', true)
                .limit(1);
            if (this.liveRms) {
              await this.liveRms.ready();
              return this.liveRms;
            }
          }
        });
    } else {
      this.liveRms.query = ref =>
        ref
          .where('domain', '==', id)
          .where('active', '==', true)
          .limit(1);
      if (this.liveRms) {
        await this.liveRms.ready();
        return this.liveRms;
      }
    }
  };
}

export default createContext(new Rmss());
