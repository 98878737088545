import React from 'react';
import './styles.module.css';

/**
 * Loading component
 * Loading spinning svg
 */
export default function Loading({
  minHeight = '80px',
  maxHeight = '',
  color = '#f2f2f2',
  stylename = '',
  style = {}
}) {
  return (
    <div
      styleName={`loading-container`}
      className={`loading-${stylename}`}
      style={{
        ...style,
        minHeight: minHeight,
        maxHeight: maxHeight
      }}
    >
      <svg
        styleName="spinner"
        width="65px"
        height="65px"
        viewBox="0 0 66 66"
        xmlns="https://www.w3.org/2000/svg"
      >
        <circle
          styleName="circle"
          fill="none"
          stroke={color}
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  );
}
