import React, { useContext, useEffect, useState } from 'react';
import './styles.module.css';
import Billing from '../../stores/Billing';
import AITokens from '../../stores/AIToken';
import { observer } from 'mobx-react-lite';

/**
 * AITokenCounter component
 */
export default observer(AITokenCounter);
function AITokenCounter({
  style,
  tokenFromUsage = 0,
  customStyle = '',
  noCounterDisplay = false,
  onlyCounterDisplay = false
}) {
  const { aiTokenTotal, aiTokenDisplay } = useContext(AITokens);
  const billing = useContext(Billing);
  const [token, setToken] = useState(tokenFromUsage);
  const [sideBarToken, setSideBarToken] = useState(tokenFromUsage.toString());

  function handleToken(event) {
    event.preventDefault();
    billing.needUpgrade('credits');
  }

  useEffect(() => {
    setToken((aiTokenTotal / 1000).toFixed(0));
    setSideBarToken(aiTokenDisplay);
  }, [aiTokenDisplay]);

  return (
    <>
      {!noCounterDisplay && (
        <>
          {customStyle === 'sidebar-ai-counter' ? (
            <div styleName={`token`} className={customStyle}>
              <button onClick={handleToken}>
                <div
                  className="unvisible-section"
                  styleName="generator_none_section "
                >
                  <div>
                    <b> AI credits</b>
                  </div>
                  <div>
                    <span className="icon-button-label">{sideBarToken}</span>
                    <i
                      className={`fas fa-solid fa-coin${
                        sideBarToken.indexOf('k') !== -1 ? 's' : ''
                      }`}
                    />
                  </div>
                </div>
                <div className="visible-section" styleName="generator_section">
                  <i
                    className={`fas fa-solid fa-coin${
                      sideBarToken.indexOf('k') !== -1 ? 's' : ''
                    }`}
                  />
                  <span className="icon-button-label">
                    AI credits {sideBarToken}
                  </span>
                </div>
              </button>
            </div>
          ) : onlyCounterDisplay ? (
            <span>{token > 1 ? `${token}k` : token <= 0 ? '0' : '<1k'}</span>
          ) : (
            <div style={style} styleName={`token`} className={customStyle}>
              <button onClick={handleToken}>
                <i className="fas fa-coin" />
                <span className="icon-button-label">
                  {token > 1 ? `${token}k` : token <= 0 ? '0' : '<1k'}
                  <b> AI credits</b>
                </span>
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}
