import React, { useState, useEffect }  from 'react';
import ReactSelect from 'react-select/creatable';
import Field from '../Field';
import '../Select/styles.module.css';

/**
 * SelectState component
 * SelectState form input
 * @property {array} options SelectState options
 * @properties Inherited from Field
 */
export default function SelectState({ className, inputClassName, country, regionalState = null, ...attrs }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    country = country ? country : 'United State';
    async function fetchData() {
      await fetch(`/assets/localisation/${country}.json`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const focusData = regionalState ? data[regionalState] : Object.keys(data);
          setData(focusData.sort().map( s => {
            return{
              value: s,
              label: s
            }
          }));
        })
    }
    fetchData();
  }, [country, regionalState]);
  return (
    <Field
      styleName="select"
      InputElement={ReactSelect}
      options={data}
      classNamePrefix="select"
      className={className || ''}
      inputClassName={inputClassName || ''}
      {...attrs}
    />
  );
}
