import { createContext } from 'react';
import { auth, db } from '../lib/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, onSnapshot, query, where, doc } from 'firebase/firestore';
import { makeAutoObservable, computed, runInAction } from 'mobx';

class AITokens {
  aiToken = null;
  aiTokenUsage = null;

  constructor() {
    makeAutoObservable(this, {
      aiTokenCount: computed,
      aiTokenDisplay: computed
    });

    onAuthStateChanged(auth, async user => {
      if (user && user.uid) {
        this.unsubscribeTokenOrder = onSnapshot(
          query(collection(db, 'token_order'), where('id', '==', user.uid)),
          querySnapshot => {
            runInAction(() => {
              this.aiToken = querySnapshot;
            });
          },
          error => {
            console.log(error);
          }
        );

        this.unsubscribeTokenUsage = onSnapshot(
          doc(db, 'token_usage_autocomplete', user.uid),
          querySnapshot => {
            runInAction(() => {
              this.aiTokenUsage = querySnapshot;
            });
          },
          error => {
            console.log(error);
          }
        );
      } else {
        // Unsubscribe listener
        runInAction(() => {
          this.aiTokenUsage = null;
          this.aiToken = null;
          if (this.unsubscribeTokenOrder) {
            this.unsubscribeTokenOrder();
          }
          if (this.unsubscribeTokenUsage) {
            this.unsubscribeTokenUsage();
          }
        });
      }
    });
  }

  get aiTokenTotal() {
    //Compute total from snapshot
    let tokenBought =
      this.aiToken && this.aiToken.docs && this.aiToken.docs.length > 0
        ? this.aiToken.docs.map(doc => {
            let orders = Object.values(doc.data());
            orders = orders.filter(order => typeof order === 'number');
            let tokens = 0;
            tokens += orders.reduce((a, b) => a + b, 0);
            return tokens;
          })
        : [0];

    let tokenConsume =
      this.aiTokenUsage && this.aiTokenUsage.data()
        ? this.aiTokenUsage.data().global
        : 0;
    let total = tokenBought[0];
    if (tokenConsume && tokenConsume > 0) {
      total -= tokenConsume;
    }
    return total;
  }

  get aiTokenDisplay() {
    //Compute total display from aiTokenTotal
    const total = this.aiTokenTotal;
    return total > 100000
      ? (total / 1000).toFixed(0) + 'k'
      : total <= 0
      ? '0'
      : total.toString().replace(/(.)(?=(\d{3})+$)/g, '$1,');
  }
}

export default createContext(new AITokens());
