import { Document } from 'firestorter';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  linkWithCredential,
  signInWithCustomToken,
  signOut,
  reauthenticateWithCredential,
  reauthenticateWithPopup
} from 'firebase/auth';
import { observable } from 'mobx';
import { createContext } from 'react';
import { struct } from 'superstruct';
import { FUNCTIONS_ENDPOINT } from '../lib/constants';
import firebase, { auth } from '../lib/firebase';
import { notify } from '../components/Notification';
import 'whatwg-fetch';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { trackingSignup } from '../utils/tracking';
import { useTranslation } from 'react-i18next';
import lsTest from '../utils/lsAvailable';

export const UserSchema = struct({
    email: 'string?',
    authProvider: 'string',
    firstName: 'string?',
    lastName: 'string?',
    billing: {
      stripeId: 'string?',
      templates: ['string']
    },
    phone: 'string?',
    linkedin: 'string?',
    website: 'string?',
    job: {
      industry: 'string?',
      city: 'string?',
      country: 'string?'
    },
    createdAt: 'string?',
    lastSeen: 'string?',
    uploadCount: 'number?',
    startUsing: 'string?',
    whiteLabel: 'string?',
    completion: 'object?',
    affiliate: 'string?',
    emailVerified: 'boolean?',
    feedback: 'object?',
    referee: 'array?',
    referrer: 'string?',
    department: 'string?',
    major: 'string?',
    studentId: 'string?',
    customToken: 'boolean?',
    bvrLicenseVersion: 'string?',
    isBvrUser: 'string?'
  }),
  DEFAULTS = {
    firstName: '',
    lastName: '',
    phone: '',
    linkedin: '',
    website: '',
    job: {}
  };

let USER_DATA_TEMP = {};
const RmsPartnerSchema = struct({
  update: 'string',
  token: 'string?'
});

class RmsPartner extends Document {
  constructor(source, options = {}) {
    super(source, {
      schema: RmsPartnerSchema,
      ...options
    });
  }
}

const getCookie = cname => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

async function createCustomer(email) {
  const emailProvider = email.split('@')[1];
  const rms_partner = async emailProvider => {
    const rmsDomain =
      window.location.hostname.split('.')[0] +
      '.' +
      window.location.hostname.split('.')[1];
    return await new RmsPartner(`rms_partner/${emailProvider}`)
      .fetch()
      .then(async partner => {
        if (partner.data && partner.data.update) {
          return {
            update: partner.data.update,
            token: partner.data.token || 0
          };
        } else {
          return await new RmsPartner(`rms_partner/${rmsDomain}`)
            .fetch()
            .then(async partner => {
              if (partner.data && partner.data.update) {
                return {
                  update: partner.data.update,
                  token: partner.data.token || 0
                };
              } else {
                return null;
              }
            });
        }
      });
  };

  const data = await JSON.stringify({
    email,
    rms_partner: await rms_partner(emailProvider)
  });
  const customer = await window
    .fetch(`${FUNCTIONS_ENDPOINT}/stripe/customer/new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: data
    })
    .then(res => res.json());

  return customer.id;
}

async function recoverStripeId(email) {
  const emailProvider = email.split('@')[1];
  const rms_partner = async emailProvider => {
    const rmsDomain =
      window.location.hostname.split('.')[0] +
      '.' +
      window.location.hostname.split('.')[1];
    return await new RmsPartner(`rms_partner/${emailProvider}`)
      .fetch()
      .then(async partner => {
        if (partner.data && partner.data.update) {
          return partner.data.update;
        } else {
          return await new RmsPartner(`rms_partner/${rmsDomain}`)
            .fetch()
            .then(async partner => {
              if (partner.data && partner.data.update) {
                return partner.data.update;
              } else {
                return null;
              }
            });
        }
      });
  };
  const customer = await window
    .fetch(`${FUNCTIONS_ENDPOINT}/stripe/recover`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        rms_partner: await rms_partner(emailProvider)
      })
    })
    .then(res => res.json());
  if (customer.data && customer.data[0]) {
    return customer.data[0].id;
  }
}

class UserProfile extends Document {
  constructor(source, options = {}) {
    super(source, {
      schema: UserSchema,
      mode: 'on',
      ...options
    });
  }
}

export class User {
  constructor(manual) {
    onAuthStateChanged(auth, user => {
      if (user) {
        this.user.path = `users/${user.uid}`;
        this.user.emailVerified =
          user.emailVerified ||
          (user.reloadUserInfo && user.reloadUserInfo.emailVerified) ||
          (user.providerData &&
            user.providerData[0] &&
            user.providerData[0].providerId &&
            user.providerData[0].providerId === 'customToken');
        if (manual) {
          return;
        }
        (async () => {
          try {
            await this.user.ready();

            if (user && !this.user.hasData) {
              console.info('Creating new user');
              trackingSignup(user);

              if (
                user.providerData &&
                user.providerData[0] &&
                user.providerData[0].providerId.indexOf('google') !== -1 &&
                window.location.href.includes('app.rezi.ai')
              ) {
                await window.fetch(`${process.env.PROD_API}/sendWelcomeEmail`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    email: user.email
                  })
                });
              }

              const templates = await window
                .fetch(`${FUNCTIONS_ENDPOINT}/stripe/templates`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  }
                })
                .then(res => res.json());

              const refId =
                lsTest() === true
                  ? window.localStorage.getItem('rf-rezi')
                  : null;
              const affiliate =
                lsTest() === true
                  ? window.localStorage.getItem('affiliate-rezi-id')
                  : null;
              const getCookie = cname => {
                if (document.cookie) {
                  const name = cname + '=';
                  const ca = document.cookie.split(';');
                  for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) === ' ') {
                      c = c.substring(1);
                    }
                    if (c.indexOf(name) === 0) {
                      return c.substring(name.length, c.length);
                    }
                  }
                }
                return '';
              };
              const hasPartnerCookie = await getCookie('partner');
              const whiteLabel = window.location.hostname.includes('rezi')
                ? window.location.hostname.split('.')[0]
                : getCookie('wlrezi');

              await this.user.set({
                ...USER_DATA_TEMP,
                email: !user.isAnonymous ? user.email : '',
                authProvider: !user.isAnonymous
                  ? user.providerData.length > 0
                    ? user.providerData[0].providerId
                    : 'customToken'
                  : 'anonymous',
                billing: {
                  stripeId: '',
                  templates: []
                },
                createdAt: String(new Date().getTime()),
                affiliate: affiliate ? affiliate : '',
                referrer: refId ? refId : '',
                ...DEFAULTS
              });
              if (lsTest() === true) {
                window.localStorage.removeItem('affiliate-rezi-id');
                window.localStorage.removeItem('rf-rezi');
              }
              await this.user.update({
                billing: {
                  stripeId: !user.isAnonymous
                    ? await createCustomer(user.email)
                    : '',
                  templates: templates
                    .filter(template => template.price === 0)
                    .map(template => template.id)
                },
                whiteLabel: hasPartnerCookie
                  ? hasPartnerCookie
                  : whiteLabel !== 'app'
                  ? whiteLabel
                  : ''
              });
              await window.fetch(`${FUNCTIONS_ENDPOINT}/intercom/user/update`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  email: user.email,
                  custom_attributes: {
                    affiliated: !!affiliate,
                    whiteLabel: hasPartnerCookie
                      ? hasPartnerCookie
                      : whiteLabel !== 'app'
                      ? whiteLabel
                      : ''
                  }
                })
              });
              window.dispatchEvent(new Event('new-user'));
            }
            if (!user.isAnonymous) {
              if (process.env.GENERAL_ENV === 'production') {
                if (!!Sentry) {
                  Sentry.setUser({
                    email: user.email ? user.email : 'guest',
                    id: user.uid ? user.uid : '0000'
                  });
                  Sentry.setContext('user_info', {
                    provider:
                      user.providerData.length > 0
                        ? user.providerData[0].providerId
                        : 'customToken'
                  });
                }
              }
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'user-login-success',
                method:
                  user.providerData.length > 0
                    ? user.providerData[0].providerId
                    : 'customToken',
                'user-id': user.uid
              });
              window.dispatchEvent(new Event('new-session'));
              if (user.email) {
                window
                  .fetch(`${FUNCTIONS_ENDPOINT}/intercom/hash`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      id: user.email
                    })
                  })
                  .then(res => res.text())
                  .then(resp => {
                    if (resp && (resp[0] === '[' || resp[0] === '{')) {
                      window.intercomSettings = {
                        app_id: 'jv0knjo3',
                        name: user.displayName ? user.displayName : '',
                        email: user.email,
                        created_at: user.createdAt
                          ? user.createdAt
                          : moment().format('X'),
                        user_hash: JSON.parse(resp).hash
                      };
                    }
                  });
              }
              if (this.user && this.user.data.email && user.uid) {
                auth.currentUser &&
                  auth.currentUser
                    .getIdTokenResult(true)
                    .then(idTokenResult => {
                      const data = JSON.stringify({
                        user: this.user.id,
                        email: this.user.data.email
                      });
                      const headers = new Headers();
                      headers.append('Content-Type', `application/json`);
                      headers.append('Content-Length', data.length);
                      headers.append(
                        'Authorization',
                        `Bearer ${idTokenResult.token}`
                      );
                      window.fetch(`${process.env.PROD_API}/initUsers`, {
                        method: 'POST',
                        headers: headers,
                        body: data
                      });
                    });
              }
              if (
                user.emailVerified &&
                this.user &&
                this.user.data &&
                this.user.data.referrer &&
                this.user.data.referrer !== ''
              ) {
                const data = JSON.stringify({
                  refereeId: this.user.data.email,
                  referrerId: this.user.data.referrer
                });
                window.fetch(`${process.env.PROD_API}/setReferral`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Content-Length': data.length
                  },
                  body: data
                });
              }
              async function inIframe() {
                try {
                  return window.self !== window.top;
                } catch (e) {
                  return true;
                }
              }
              inIframe().then(isIframe => {
                if (!isIframe) {
                  if (
                    this.user.data.email &&
                    this.user.id &&
                    window.location.search.indexOf('nc=true') < 0
                  ) {
                    auth.currentUser
                      .getIdTokenResult(true)
                      .then(idTokenResult => {
                        const data = JSON.stringify({
                          user: this.user.id,
                          email: this.user.data.email
                        });
                        const headers = new Headers();
                        headers.append('Content-Type', `application/json`);
                        headers.append('Content-Length', data.length);
                        headers.append(
                          'Authorization',
                          `Bearer ${idTokenResult.token}`
                        );
                        window.fetch(`${process.env.PROD_API}/claimAdmin`, {
                          method: 'POST',
                          headers: headers,
                          body: data
                        });
                      });
                  }
                }
              });
            }
            this.ready.set(true);
            this.user.update({
              emailVerified: user.emailVerified,
              lastSeen: String(new Date().getTime())
            });

            if (
              !this.user.data.billing.stripeId &&
              this.user.data.authProvider !== 'anonymous'
            ) {
              const recover = await recoverStripeId(user.email);
              await this.user.update({
                'billing.stripeId': recover ? recover : ''
              });
              location.reload();
            }
          } catch (err) {
            console.error(err);
          }
        })();
      } else {
        this.user.path = null;
        this.ready.set(true);
      }
    });
  }
  user = new UserProfile();

  ready = observable.box(false);

  signup = async (provider, credentials) => {
    const userData = { ...credentials };

    if (this.user.path && this.user.data.authProvider === 'anonymous') {
      let credential;

      if (provider === 'email') {
        credential = EmailAuthProvider.credential(
          credentials.email,
          credentials.password
        );
      } else {
        const { credential: result } = await this.login(provider);
        credential =
          provider === 'google'
            ? GoogleAuthProvider.credential(result.accessToken)
            : FacebookAuthProvider.credential(result.accessToken);
      }

      const { user } = await linkWithCredential(auth.currentUser, credential);

      await this.user.update({
        email: user.email,
        authProvider:
          user.providerData.length > 0
            ? user.providerData[0].providerId
            : 'customToken',
        'billing.stripeId': await createCustomer(user.email),
        lastSeen: String(new Date().getTime())
      });

      window.dispatchEvent(new Event('new-session'));

      location.reload();
    }
    const auth = getAuth();
    return provider === 'email'
      ? createUserWithEmailAndPassword(
          auth,
          credentials.email,
          credentials.password
        ).then(() => {
          delete userData.password;
          USER_DATA_TEMP = { ...userData };
        })
      : this.login(provider);
  };

  sendOobMail = async (email, from, rms) => {
    let data = {
      email,
      from,
      rms
    };
    let errorCode;
    if (!email || !from) return;
    if (from === 'verify_email') {
      onAuthStateChanged(auth, user => {
        if (user) {
          data.email = user.email;
        } else {
          return;
        }
      });
    }
    await window
      .fetch(`${process.env.PROD_API}/sendOobCodeEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response =>
        response.status > 400 ? (errorCode = response.status) : response.json()
      );
    if (errorCode) return { error: errorCode };
    else return true;
  };

  reAuthenticate = password => {
    const user = auth.currentUser;

    switch (this.user.data.authProvider) {
      case 'password':
        return reauthenticateWithCredential(
          user,
          EmailAuthProvider.credential(this.user.data.email, password)
        );
      case 'google.com':
        return reauthenticateWithPopup(user, new GoogleAuthProvider());
      case 'facebook.com':
        return reauthenticateWithPopup(user, new FacebookAuthProvider());
    }
  };

  login = (provider, credentials) => {
    switch (provider) {
      case 'email':
        return signInWithEmailAndPassword(
          auth,
          credentials.email.trim(),
          credentials.password
        );
      case 'google':
        return signInWithPopup(auth, new GoogleAuthProvider());
      case 'facebook':
        return signInWithPopup(auth, new FacebookAuthProvider());
      case 'custom_token':
        return signInWithCustomToken(auth, credentials.token);
      default:
        return null;
    }
  };

  logout = () => {
    const auth = getAuth();
    signOut(auth);
    window.intercomSettings = {
      app_id: 'jv0knjo3'
    };
    this.user.data.authProvider = '';
    this.user.path = null;
  };

  saveResumeRate = (id, value) => {
    let completionList = this.user.data.completion
      ? this.user.data.completion
      : {};
    completionList[id] = value;
    this.user.update({
      completion: completionList
    });
  };

  sendWelcomeMail = async (email, rms) => {
    try {
      if (!rms) {
        return await window.fetch(`${process.env.PROD_API}/sendWelcomeEmail`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email
          })
        });
      }
    } catch (err) {
      return err;
    }
  };
}

export default createContext(new User());
