import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Billing from '../../../stores/Billing';
import { calculPriceToDisplay } from './action';
import moment from 'moment';

/**
 * PriceDisplay component
 */
export default function PriceDisplay({
  plan,
  coupon,
  billed,
  forceFloat = false,
  addCredit = false,
  subscriptionBase = false,
  resumeReview = null
}) {
  const { t } = useTranslation();
  const billing = useContext(Billing);
  const { customer } = useContext(Billing);
  const planJson = require('../price.json');
  const credits = { price: 10, duration: 'once', value: 'sku_IkTXh9S4RsvNfj' };
  const get = planName => planJson.priceTable[billed][planName];
  const currentPlanId = customer.plan;
  const currentPlan =
    currentPlanId &&
    customer.subscriptions &&
    customer.subscriptions.data.length > 0
      ? customer.subscriptions.data.filter(sub => {
          return sub.plan.product === currentPlanId;
        })[0]
      : null;
  const canceledAt = currentPlan && currentPlan.cancel_at;

  return (
    <>
      {!forceFloat &&
        plan &&
        billing.customerPlan &&
        billing.customerPlan.id &&
        billing.customerPlan.id === get(plan).value && (
          <div>
            {canceledAt
              ? 'Cancel on ' + moment(canceledAt, 'X').format('MMM. Do, YYYY')
              : t('modal_plan_payment.current')}
          </div>
        )}
      {resumeReview ? (
        <>
          {calculPriceToDisplay(
            coupon,
            billed,
            get(plan),
            false,
            forceFloat,
            addCredit,
            credits,
            resumeReview
          ) != resumeReview.price && <strike>${resumeReview.price}</strike>}
          <span>
            {resumeReview.price === 0
              ? 'Free'
              : '$' +
                calculPriceToDisplay(
                  coupon,
                  billed,
                  get(plan),
                  false,
                  forceFloat,
                  addCredit,
                  credits,
                  resumeReview
                )}
          </span>
        </>
      ) : plan ? (
        <>
          {calculPriceToDisplay(
            coupon,
            billed,
            get(plan),
            subscriptionBase,
            forceFloat,
            addCredit,
            credits
          ) !=
            get(plan).price *
              (subscriptionBase &&
              get(plan).duration === 'forever' &&
              billed === 'Quarterly'
                ? 3
                : 1) +
              (addCredit ? credits.price : 0) && (
            <strike>
              $
              {plan
                ? get(plan).price *
                    (subscriptionBase &&
                    get(plan).duration === 'forever' &&
                    billed === 'Quarterly'
                      ? 3
                      : 1) +
                  (addCredit ? credits.price : 0)
                : credits.price}
            </strike>
          )}
          <span>
            {get(plan).price === 0 && !addCredit
              ? 'Free'
              : '$' +
                calculPriceToDisplay(
                  coupon,
                  billed,
                  get(plan),
                  subscriptionBase,
                  forceFloat,
                  addCredit,
                  credits
                )}
          </span>

          <small>
            {!forceFloat &&
            get(plan).price !== 0 &&
            get(plan).duration === 'forever'
              ? '/' + t('modal_plan_payment.month')
              : ''}
          </small>
        </>
      ) : (
        <>
          {calculPriceToDisplay(
            coupon,
            billed,
            credits,
            subscriptionBase,
            forceFloat,
            addCredit,
            credits
          ) != credits.price && <strike>${credits.price}</strike>}
          $
          {calculPriceToDisplay(
            coupon,
            billed,
            credits,
            subscriptionBase,
            forceFloat,
            addCredit,
            credits
          )}
        </>
      )}
    </>
  );
}
