import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { initFirestorter } from 'firestorter';

const isDev =
  window.location.hostname.split('.')[0] === 'develop--rezi' ||
  window.location.hostname.split('.')[0] === 'localhost';
const CONFIG = {
  apiKey: isDev
    ? process.env.DEV_FIREBASE_API_KEY
    : process.env.FIREBASE_API_KEY,
  authDomain: isDev
    ? process.env.DEV_FIREBASE_AUTH_DOMAIN
    : process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: isDev
    ? `https://${process.env.DEV_FIREBASE_PROJECT_ID}.firebaseio.com`
    : `https://${process.env.FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: isDev
    ? process.env.DEV_FIREBASE_PROJECT_ID
    : process.env.FIREBASE_PROJECT_ID,
  storageBucket: isDev
    ? process.env.DEV_FIREBASE_STORAGE_BUCKET
    : process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: isDev
    ? process.env.DEV_FIREBASE_SENDER_ID
    : process.env.FIREBASE_SENDER_ID,
  appId: isDev ? process.env.DEV_FIREBASE_APP_ID : process.env.FIREBASE_APP_ID
};

export let auth;
export let db;
if (!firebase.apps.length) {
  firebase.initializeApp(CONFIG);
  initFirestorter({ firebase: firebase });
  const firebaseApp = initializeApp(CONFIG);
  auth = getAuth(firebaseApp);
  db = getFirestore();
}

export default firebase;
