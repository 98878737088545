import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Billing from '../../stores/Billing';
import Resumes from '../../stores/Resumes';
import CoverLetters from '../../stores/CoverLetters';
import Loading from '../../components/Loading';

/**
 * Downgrade component
 * HOC Downgrade modal
 */
export default observer(Downgrade);
function Downgrade({ children }) {
  const billing = useContext(Billing);
  const { customer } = useContext(Billing);
  const { resumes, updateResume } = useContext(Resumes);
  const { coverLetters, updateCoverLetter } = useContext(CoverLetters);

  const [pending, setPending] = useState(false);
  const planInitialised = customer && customer.subscriptions;
  const isFreePlan =
    customer &&
    customer.subscriptions &&
    customer.subscriptions.data[billing.productIndex(customer)]
      ? customer.subscriptions.data[billing.productIndex(customer)].items
          .data[0].plan.id === 'plan_FfroNQtjGkZjYS'
      : true;

  const haveUnlockedResume =
    resumes && resumes.docs.length > 1
      ? resumes.docs.filter(resume => !resume.data.locked).length > 1
      : false;

  const haveUnlockedCoverLetter =
    coverLetters && coverLetters.docs.length > 1
      ? coverLetters.docs.filter(cl => !cl.data.locked).length > 0
      : false;

  const needLockData =
    planInitialised &&
    isFreePlan &&
    (haveUnlockedResume || haveUnlockedCoverLetter);

  useEffect(() => {
    if (needLockData) {
      setPending(true);
      handleLockResume().then(() => setPending(false));
    } else if (planInitialised && !isFreePlan) {
      handleUnlockResume();
    }
  }, [needLockData, planInitialised]);

  const handleLockResume = async () => {
    resumes.docs
      .sort((adoc, bdoc) => bdoc.data.createdAt - adoc.data.createdAt)
      .map(async ({ id }, i) => {
        await updateResume(id, { locked: i !== 0, dlCount: 0 });
      });
    if (haveUnlockedCoverLetter) {
      coverLetters.docs.map(async ({ id }) => {
        await updateCoverLetter(id, {
          locked: true
        });
      });
    }
  };

  const handleUnlockResume = async () => {
    resumes.docs.length > 0 &&
      resumes.docs.map(async (resume, i) => {
        if (resume.data.locked) {
          await updateResume(resume.id, { locked: false });
        }
      });
    coverLetters.docs.length > 0 &&
      coverLetters.docs.map(async coverLetter => {
        if (coverLetter.data.locked) {
          await updateCoverLetter(coverLetter.id, {
            locked: false
          });
        }
      });
  };

  return <>{pending ? <Loading /> : <>{children}</>}</>;
}
