import { Collection, Document } from 'firestorter';
import { createContext } from 'react';
import { struct } from 'superstruct';
import { auth } from '../lib/firebase';
import { DEFAULT_TEMPLATE_ID } from '../lib/constants';
import moment from 'moment';
import { onAuthStateChanged } from 'firebase/auth';

const CoverSchema = struct({
    user: 'string',
    name: 'string',
    archived: 'boolean',
    locked: 'boolean',
    template: 'string',
    createdAt: 'string?',
    config: {
      lineHeight: 'number',
      fontSize: 'number',
      showIcons: 'boolean',
      sections: {
        content: 'object?',
        contact: {
          heading: 'string',
          index: 'number'
        }
      }
    },
    indexed: 'boolean',
    generator: 'object?',
    data: {
      contact: {
        name: 'string?',
        email: 'string?',
        phone: 'string?',
        date: 'string?',
        address: 'string?',
        company: 'string?',
        addressto: 'string?'
      },
      content: 'object?'
    }
  }),
  DEFAULTS = {
    archived: false,
    locked: false,
    template: DEFAULT_TEMPLATE_ID,
    indexed: true,
    config: {
      lineHeight: 1.5,
      fontSize: 9,
      showIcons: true,
      sections: {
        content: {
          heading: 'Content',
          index: 0
        },
        contact: {
          heading: 'Contact',
          index: 7
        }
      }
    },
    data: {
      contact: {},
      content: {}
    }
  };

class CoverLetter extends Document {
  constructor(source, options = {}) {
    super(source, {
      schema: CoverSchema,
      ...options
    });
  }

  get hasUserData() {
    const { data } = this.data;

    return !Object.values(data).every(field => !Object.keys(field).length);
  }
}

class CoverLetters {
  constructor() {
    onAuthStateChanged(auth, user => {
      this.coverLetters.query = ref => {
        return user ? ref.where('user', '==', user.uid) : null;
      };
    });
  }

  coverLetters = new Collection('coverletters', {
    createDocument: (source, options) => new CoverLetter(source, options),
    query: ref => null,
    mode: 'on'
  });

  coverLetter = new Collection('coverletters', {
    createDocument: (source, options) => new CoverLetter(source, options),
    query: ref => null,
    mode: 'off'
  });

  _coverLetterDoc = id => new CoverLetter(`coverletters/${id}`);

  getCoverLetter = async coverId => {
    return this.coverLetters.docs.find(({ id }) => id === coverId);
  };

  addCoverLetter = async data => {
    return await this.coverLetter
      .add({
        user: auth.currentUser.uid,
        ...DEFAULTS,
        ...data,
        createdAt: moment().format('X')
      })
      .then(data => {
        return data.id;
      });
  };

  updateCoverLetter = (id, data) => {
    return this._coverLetterDoc(id)
      .fetch()
      .then(coverletter => coverletter.update(data));
  };

  editCoverLetterItem = (coverletter, coverId, field, item) => {
    const itemKey = 'data.' + field;
    return coverletter.update({
      [itemKey]: { ...item }
    });
  };

  deleteCoverLetter = id => this._coverLetterDoc(id).delete();
}

export default createContext(new CoverLetters());
