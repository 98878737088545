import { globalHistory, Redirect, Router } from '@reach/router';
import polyfill from 'dynamic-polyfill';
import { observer } from 'mobx-react-lite';
import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  Suspense,
  lazy
} from 'react';
import Rms from '../../hoc/Rms';
import CacheBuster from '../../utils/compareSemanticVersion';
import Route from '../../components/Route';
import { FUNCTIONS_ENDPOINT } from '../../lib/constants';
import Billing from '../../stores/Billing';
import User from '../../stores/User';
import RmsStore from '../../stores/Rms';
import './css/index.css';
import styles from './styles.module.css';
import 'react-image-lightbox/style.css';
import 'whatwg-fetch';
import './thirdPartServices';
import moment from 'moment';
import { HelmetProvider } from 'react-helmet-async';
import SplashScreen from '../../components/SplashScreen';
import('./intercomServices');

import Upgrade from '../../hoc/Upgrade';
import Downgrade from '../../hoc/Downgrade';

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../lib/firebase';
import Notification from '../../components/Notification';
import lsTest from '../../utils/lsAvailable';
const SideBarHoc = lazy(() => import('../../hoc/SideBarHoc'));
const Authentication = lazy(() => import('../Authentication'));
const ResetPassword = lazy(() => import('../ResetPassword'));
const Fp = lazy(() => import('../InternalCookiePage'));
const Share = lazy(() => import('../Share'));
const ConfirmEmail = lazy(() => import('../ConfirmEmail'));
const NotFound = lazy(() => import('../../components/NotFound'));

// 'Use' styles to stop linters removing it
styles;

const POLYFILLS = ['Array.from'];

function dynamicPolyfill() {
  const cssVars = {
    test:
      ((window || {}).CSS || {}).supports && window.CSS.supports('(--a: 0)'),
    config: {
      watch: true,
      updateURLs: false,
      onComplete() {
        setTimeout(() => (document.body.style.visibility = 'visible'), 10);
      }
    }
  };

  POLYFILLS.length &&
    polyfill({
      fills: POLYFILLS,
      rum: false,
      afterFill() {
        return null;
      }
    });

  // Custom polyfill for CSS Variables
  if (!cssVars.test) {
    import(
      /* webpackChunkName: "css-vars-ponyfill" */ 'css-vars-ponyfill'
    ).then(({ default: cssVarsPonyfill }) => {
      document.body.style.visibility = 'hidden';
      cssVarsPonyfill(cssVars.config);
    });
  }
}

function newSessionUser(user) {
  window.fetch(`${FUNCTIONS_ENDPOINT}/intercom/user/update`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: user.email,
      signed_up_at: Math.trunc(user.createdAt / 1000),
      new_session: true
    })
  });
  if (mixpanel) {
    mixpanel.people.set({
      $email: user.email,
      'Sign up date': moment(user.createdAt, 'x').toISOString(),
      USER_ID: user.id
    });
    mixpanel.identify(user.id);
  }
  if (profitwell) {
    profitwell('start', { user_email: user.email });
  }
  if (dataLayer) {
    dataLayer.push({ event: 'start_pw', pw_user_email: user.email });
  }
}

/**
 * App container
 * Entrypoint for the application
 */
const App = observer(() => {
  const [state, setState] = useState({
    sidebar: false,
    stripeInstance: null
  });

  const anchorForScript = useRef(null);
  const { store } = useContext(RmsStore);
  const { ready, user, login, logout } = useContext(User);
  const billing = useContext(Billing);
  const { customer } = useContext(Billing);
  const [authenticated, setAuthenticated] = useState(!!user.path);
  const sharedRmsProps = {
    title: store.rmsTitle,
    rmsInfos: store.rmsHasInfos ? store.rmsElement : {}
  };

  const useKit = () => {
    let d = document;
    var config = {
        kitId: 'xtv1isi',
        scriptTimeout: 3000,
        async: true
      },
      h = d.documentElement,
      t = setTimeout(function() {
        h.className =
          h.className.replace(/\bwf-loading\b/g, '') + ' wf-inactive';
      }, config.scriptTimeout),
      tk = d.createElement('script'),
      f = false,
      s = d.getElementsByTagName('script')[0],
      a;
    h.className += ' wf-loading';
    tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
    tk.async = true;
    tk.onload = tk.onreadystatechange = function() {
      a = this.readyState;
      if (f || (a && a != 'complete' && a != 'loaded')) return;
      f = true;
      clearTimeout(t);
      try {
        Typekit.load(config);
      } catch (e) {}
    };
    s.parentNode.insertBefore(tk, s);
  };
  const mixpanelScript = (c, a) => {
    if (!a.__SV) {
      var b = window;
      try {
        var d,
          m,
          j,
          k = b.location,
          f = k.hash;
        d = function(a, b) {
          return (m = a.match(RegExp(b + '=([^&]*)'))) ? m[1] : null;
        };
        f &&
          d(f, 'state') &&
          ((j = JSON.parse(decodeURIComponent(d(f, 'state')))),
          'mpeditor' === j.action &&
            (b.sessionStorage.setItem('_mpcehash', f),
            history.replaceState(
              j.desiredHash || '',
              c.title,
              k.pathname + k.search
            )));
      } catch (n) {}
      var l, h;
      window.mixpanel = a;
      a._i = [];
      a.init = function(b, d, g) {
        function c(b, i) {
          var a = i.split('.');
          2 == a.length && ((b = b[a[0]]), (i = a[1]));
          b[i] = function() {
            b.push([i].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        var e = a;
        'undefined' !== typeof g ? (e = a[g] = []) : (g = 'mixpanel');
        e.people = e.people || [];
        e.toString = function(b) {
          var a = 'mixpanel';
          'mixpanel' !== g && (a += '.' + g);
          b || (a += ' (stub)');
          return a;
        };
        e.people.toString = function() {
          return e.toString(1) + '.people (stub)';
        };
        l = 'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(
          ' '
        );
        for (h = 0; h < l.length; h++) c(e, l[h]);
        var f = 'set set_once union unset remove delete'.split(' ');
        e.get_group = function() {
          function a(c) {
            b[c] = function() {
              call2_args = arguments;
              call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
              e.push([d, call2]);
            };
          }
          for (
            var b = {},
              d = ['get_group'].concat(
                Array.prototype.slice.call(arguments, 0)
              ),
              c = 0;
            c < f.length;
            c++
          )
            a(f[c]);
          return b;
        };
        a._i.push([b, d, g]);
      };
      a.__SV = 1.2;
      b = c.createElement('script');
      b.type = 'text/javascript';
      b.async = !0;
      b.src =
        'undefined' !== typeof MIXPANEL_CUSTOM_LIB_URL
          ? MIXPANEL_CUSTOM_LIB_URL
          : 'file:' === c.location.protocol &&
            '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\/\//)
          ? 'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'
          : '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js';
      d = c.getElementsByTagName('script')[0];
      d.parentNode.insertBefore(b, d);
    }
  };

  useEffect(() => {
    setAuthenticated(!!user.path);
  }, [user.path]);

  useEffect(() => {
    if (
      ready.get() &&
      process.env.GENERAL_ENV === 'production' &&
      window.location.href.includes('app.rezi.ai')
    ) {
      console.log(
        '%c🚀 We are hiring! Check the page https://www.rezi.ai/careers 🚀',
        'color: #48c9b0; font-size: 1.3em; font-weight: bolder'
      );
    }
  }, [ready.get()]);

  const initView = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://kit.fontawesome.com/119560e0cc.js';
    anchorForScript.current.appendChild(script);
    const scriptGoogle = document.createElement('script');
    scriptGoogle.async = true;
    scriptGoogle.src = 'https://apis.google.com/js/api.js';
    anchorForScript.current.appendChild(scriptGoogle);
    useKit();
    mixpanelScript(document, window.mixpanel || []);
    mixpanel.init('112f4b6d946fa5a3747251cbea1d351f', { batch_requests: true });
    if (mixpanel && customer && customer.plan) {
      const planName = {
        prod_FfrohAFnsPcvLm: 'Rezi Free',
        prod_Ffroe6Ht43gZNJ: 'Rezi Pro',
        prod_HObrDlBxT4ASyP: 'Rezi Lifetime',
        prod_HOD3Trk7tq86Ao: 'Rezi Basic - Quarterly',
        prod_HOD2oLyIlY3ISl: 'Rezi Basic - Monthly',
        prod_Gkv3mbJHn5bWLs: 'Rezi Pro - Quarterly',
        prod_Gkv2kacUL4E1br: 'Rezi Pro - Monthly',
        prod_Gkuz8WI1tg4uNu: 'Rezi Plus - Quarterly',
        prod_GkrOZum7e3Grf8: 'Rezi Plus - Monthly',
        prod_GGBWkTZPVR1NaL: 'Rezi Pro Life-time',
        prod_H8A2RqRTXRQMRW: 'Simple Rezi Pro Life-time'
      };
      mixpanel.register({
        plan: planName[customer.plan]
      });
      mixpanel.people.set({
        plan: planName[customer.plan]
      });
    }
    dynamicPolyfill();
  };

  const newUserUpdate = () => {
    if (
      user.data.authProvider !== 'anonymous' &&
      user.data.email &&
      user.data.createdAt
    ) {
      newSessionUser(user.data);
      if (user.data.billing) {
        billing.refreshCustomer(user.data.billing.stripeId);
      }
    }
  };
  const newSessionUpdate = () => {
    if (
      user.data.authProvider !== 'anonymous' &&
      user.data.email &&
      user.data.createdAt
    ) {
      newSessionUser(user.data, customer);
    }
  };
  const initUser = () => {
    window.addEventListener('new-user', newUserUpdate);
    window.addEventListener('new-session', newSessionUpdate);
  };

  const initPath = () => {
    if (window.location.search.indexOf('partner=stackcommerce') > -1) {
      const d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + d.toUTCString();
      document.cookie = 'partner=stack-commerce;' + expires + ';path=/';
    }
    if (window.location.search.indexOf('partner=dealify') > -1) {
      const d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + d.toUTCString();
      document.cookie = 'partner=dealify;' + expires + ';path=/';
    }
    if (window.location.search.indexOf('coupon=') > -1) {
      const d = new Date();
      d.setTime(d.getTime() + 2 * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + d.toUTCString();
      const coupon = window.location.search.substr(
        window.location.search.indexOf('coupon=') + 7,
        window.location.search
          .substr(
            window.location.search.indexOf('coupon=') + 7,
            window.location.search.length
          )
          .split('&')[0].length
      );
      document.cookie = 'rezicoupon=' + coupon + ';' + expires + ';path=/';
    }
    if (window.location.search.indexOf('tid') > -1) {
      const url = window.location.href,
        name = 'tid',
        regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url),
        affiliateID =
          !results || !results[2]
            ? ''
            : decodeURIComponent(results[2].replace(/\+/g, ' '));
      if (lsTest() === true)
        window.localStorage.setItem('affiliate-rezi-id', affiliateID);
    }

    //from linkedin extension
    if (window.location.search.indexOf('imp=linkedin') > -1) {
      //check if user from RMS
      const rmsNeedRedirect =
        lsTest() === true ? window.localStorage.getItem('rmsKey') : null;
      if (rmsNeedRedirect && window.localStorage) {
        if (lsTest() === true) window.localStorage.removeItem('rmsKey');
        window.location.href = `https://${rmsNeedRedirect.replace(
          /"/g,
          ''
        )}?imp=linkedin`;
      }
      if (lsTest() === true)
        window.localStorage.setItem('imp-linkedin-rezi', 'true');
    }
    const url = window.location.pathname.split('/');
    if (
      url[1] === 'invite' ||
      (lsTest() === true && window.localStorage.getItem('rf-rezi'))
    ) {
      if (url[1] === 'invite' && lsTest() === true) {
        window.localStorage.setItem('rf-rezi', url[2]);
      }
    }
  };

  const checkTokenUrl = () => {
    const token = window.location.href.split('?token=')[1];
    if (!token) return;
    return login('custom_token', { token: token });
  };

  const init = () => {
    initView();
    initPath();
    initUser();
    checkTokenUrl();
  };

  const handleViewChange = () => {
    initPath();
  };

  useEffect(() => {
    globalHistory.listen(props => {
      if (
        typeof window !== 'undefined' &&
        props.action !== 'POP' &&
        !props.location.state.noScroll
      )
        handleViewChange();
    });
    init();
    return () => {
      window.removeEventListener('new-user', newUserUpdate);
      window.removeEventListener('new-session', newSessionUpdate);
    };
  }, []);

  useEffect(() => {
    if (customer && customer.pastDue) {
      alert('Your have been downgraded, Last payment failed');
      customer.pastDue = false;
    }
  }, [customer]);

  useEffect(() => {
    if (
      auth &&
      store.isRms &&
      store.rmsElement &&
      store.rmsElement.customSessionExpired
    ) {
      onAuthStateChanged(auth, user => {
        if (user) {
          const lastLogin = user.auth.currentUser.metadata.lastLoginAt;
          const isExpired = moment(lastLogin, 'x')
            .add(
              store.rmsElement.customSessionExpired.value,
              store.rmsElement.customSessionExpired.timeRange
            )
            .isBefore(moment());
          if (isExpired) {
            logout();
          }
        }
      });
    }
  }, [store.isRms]);

  // useEffect(() => {
  //   if (!store.loadRms) {
  //     if (
  //       (store.isRms !== null && store.isRms === false) ||
  //       (store.isRms &&
  //         !(store.rmsElement.hideDom && store.rmsElement.hideDom.supportChat))
  //     ) {
  //       console.log('show', window.Intercom);
  //       window.Intercom('boot', {
  //         app_id: 'jv0knjo3'
  //       });
  //     }
  //   }
  // }, [store.loadRms, store.isRms]);
  //
  // console.log(store.loadRms, store.isRms);

  return (
    <CacheBuster>
      {(loading, isLatestVersion, currentVersion, version) => {
        return (
          <Suspense fallback={<SplashScreen />}>
            <HelmetProvider>
              <Rms />
              <div
                styleName={
                  !loading && !isLatestVersion
                    ? 'styles.updateTopBar  styles.active'
                    : 'styles.updateTopBar'
                }
              >
                We just made fix and/or add features! Please reload the page to
                get the latest version.
              </div>
              <div
                ref={anchorForScript}
                style={
                  store.rmsHasInfos
                    ? {
                        '--color-primary': store.rmsElement.color
                          ? store.rmsElement.color
                          : '',
                        '--color-violet': store.rmsElement.color
                          ? store.rmsElement.color
                          : '',
                        '--color-hover-icon': store.rmsElement.hoverIcon
                          ? store.rmsElement.hoverIcon
                          : '',
                        '--color-nav': store.rmsElement.nav
                          ? store.rmsElement.nav
                          : '',
                        '--color-nav-hover': store.rmsElement.hoverNav
                          ? store.rmsElement.hoverNav
                          : '',
                        '--color-button-created-text':
                          store.rmsElement.buttonCreated &&
                          store.rmsElement.buttonCreated.text
                            ? store.rmsElement.buttonCreated.text
                            : '',
                        '--color-button-created-background':
                          store.rmsElement.buttonCreated &&
                          store.rmsElement.buttonCreated.background
                            ? store.rmsElement.buttonCreated.background
                            : ''
                      }
                    : {
                        '--color-primary': '#4d70eb'
                      }
                }
              >
                <Downgrade>
                  <Router primary={false}>
                    <Redirect
                      from="/"
                      to="/dashboard/resumes/"
                      noThrow
                      default
                    />
                    <Route
                      Component={Share}
                      path="s/:shareId"
                      isPublic
                      {...sharedRmsProps}
                    />
                    <Route
                      Component={Authentication}
                      {...sharedRmsProps}
                      path="login"
                      type="login"
                    />
                    <Route
                      Component={Authentication}
                      {...sharedRmsProps}
                      path="signup"
                      type="signup"
                    />
                    <Route
                      Component={Authentication}
                      {...sharedRmsProps}
                      path="appsumo"
                      type="signup"
                    />
                    <Route
                      Component={Authentication}
                      {...sharedRmsProps}
                      path="invite/*"
                      type="signup"
                    />
                    <Route
                      Component={ConfirmEmail}
                      {...sharedRmsProps}
                      path="confirm-email"
                      isPublic
                    />
                    <Route
                      Component={Authentication}
                      {...sharedRmsProps}
                      path="reset"
                      type="reset"
                    />
                    <Route
                      Component={ResetPassword}
                      {...sharedRmsProps}
                      path="reset-password"
                      type="reset-password"
                    />
                    <Route Component={Fp} path="fp" isPublic />
                    <SideBarHoc
                      path="dashboard/*"
                      authenticated={authenticated}
                    />
                    <NotFound default />
                  </Router>
                </Downgrade>
              </div>
              <div styleName="styles.versionApp">
                {currentVersion
                  ? `version: ${version}-${currentVersion.split('-')[0]}`
                  : version
                  ? `version: ${version}`
                  : ''}
              </div>
              <Upgrade />
              <Notification />
            </HelmetProvider>
          </Suspense>
        );
      }}
    </CacheBuster>
  );
});

export default App;
