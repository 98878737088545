import { auth } from '../../../lib/firebase';
import { FUNCTIONS_ENDPOINT, NOTIFICATIONS } from '../../../lib/constants';
import { notify } from '../../Notification';

export async function subscribeLifeTime(redeem, customer, user, store) {
  try {
    return await auth.currentUser.getIdTokenResult(true).then(async idToken => {
      return await window
        .fetch(`${FUNCTIONS_ENDPOINT}/stripe/plans/lifetime`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            customerId: customer.id,
            planId:
              redeem.split('-')[0] !== 'STACK' &&
              redeem.split('-')[0] !== 'DEALIFY' &&
              redeem.split('-')[0] !== 'APPSUMO' &&
              redeem.split('-')[0] !== 'APPSUMO29'
                ? 'prod_H8A2RqRTXRQMRW'
                : 'prod_GGBWkTZPVR1NaL',
            redeem: redeem,
            idToken: idToken.token,
            network: (store.isRms && store.rmsElement.domain) || '',
            userId: user.id
          })
        })
        .then(res => {
          return res.json();
        })
        .then(async order => {
          if (order === 'Wrong redeem code or expired.') {
            notify(order);
            return { message: 'error' };
          }
          if (order && order.message && order.message !== 'success') {
            notify(order.message);
            return 'error';
          } else {
            return 'need unlock';
            // await Promise.all(
            //   resumes.docs.map(({ id: resume }, i) => {
            //     return i !== 0 && updateResume(resume, { locked: false });
            //   })
            // );
            // await Promise.all(
            //   coverLetters.docs.map(({ id: coverletter }) => {
            //     return updateCoverLetter(coverletter, { locked: false });
            //   })
            // );
          }
        })
        .catch(err => {
          notify(NOTIFICATIONS.error);
        });
    });
  } catch (err) {
    notify(NOTIFICATIONS.error);
  }
}

function checkCouponProductValid(coupon, product, duration) {
  if (coupon && coupon.metadata) {
    const { metadata } = coupon;
    const typeValid = coupon.duration === duration;
    return (
      typeValid &&
      (!metadata.product ||
        (metadata.product && metadata.product.includes(product)))
    );
  } else {
    return false;
  }
}

function checkCouponExpired(coupon) {
  if (!coupon.valid) {
    return true;
  }
  if (
    coupon.max_redemptions &&
    coupon.times_redeemed === coupon.max_redemptions
  ) {
    return true;
  }
  return false;
}

export function calculPriceToDisplay(
  coupon,
  billed,
  product,
  subscriptionBase = true,
  forcefloat,
  addCredit,
  credits,
  resumeReview
) {
  let defaultPrice =
    resumeReview && resumeReview.price
      ? resumeReview.price
      : onlyCreditsAndValidCoupon
      ? credits.price
      : product.price;
  let creditPriceAdded = false;
  const couponExistAndNotExpired = coupon && !checkCouponExpired(coupon);

  const onlyCreditsAndValidCoupon =
    addCredit &&
    defaultPrice === 0 &&
    checkCouponProductValid(coupon, credits.value, credits.duration);

  const havePlanAndValidCoupon =
    defaultPrice > 0 &&
    !resumeReview &&
    checkCouponProductValid(coupon, product.value, product.duration);

  const haveOrderAndValidCoupon =
    defaultPrice > 0 &&
    resumeReview &&
    resumeReview.priceId &&
    checkCouponProductValid(coupon, resumeReview.priceId, 'once');

  if (
    couponExistAndNotExpired &&
    (havePlanAndValidCoupon ||
      onlyCreditsAndValidCoupon ||
      haveOrderAndValidCoupon)
  ) {
    let price = haveOrderAndValidCoupon
      ? resumeReview.price
      : onlyCreditsAndValidCoupon
      ? credits.price
      : product.price;
    if (addCredit) {
      creditPriceAdded = true;
    }
    if (coupon.percent_off) {
      if (addCredit && !onlyCreditsAndValidCoupon) {
        price = credits.price + price;
        creditPriceAdded = true;
      }
      defaultPrice = price - price * (coupon.percent_off / 100);
    } else if (coupon.amount_off) {
      if (addCredit && !onlyCreditsAndValidCoupon) {
        price = credits.price + price;
        creditPriceAdded = true;
      }

      defaultPrice =
        price - coupon.amount_off / 100 >= 0
          ? price - coupon.amount_off / 100
          : 0;
    }
  }

  return (
    defaultPrice *
      (billed === 'Quarterly' &&
      subscriptionBase &&
      (onlyCreditsAndValidCoupon ? false : product.duration === 'forever')
        ? 3
        : 1) +
    (!creditPriceAdded && !onlyCreditsAndValidCoupon && addCredit ? 10 : 0)
  )
    .toFixed(2)
    .replace('.00', forcefloat ? '.00' : '');
}
