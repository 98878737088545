import React from 'react';
import './styles.module.css';
import { ReactComponent as TickIcon } from '../../../assets/icons/tick.svg';

/**
 * Checkbox component
 * Checkbox form input
 * @property {string} label
 */
export default function Checkbox({
  label = '',
  onChange = () => null,
  value = false,
  className,
  ...attrs
}) {
  return (
    <label styleName="checkbox" className={className || ''} {...attrs}>
      <input type="checkbox" onChange={onChange} value={value} hidden />
      <div styleName="check" data-checked={value}>
        <TickIcon hidden={!value} styleName="check-icon" />
      </div>
      <span styleName="label">{label}</span>
    </label>
  );
}
