import { Collection, Document } from 'firestorter';
import { createContext } from 'react';
import { struct } from 'superstruct';
import { auth } from '../lib/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export const OrderSchema = struct({
  amount: 'number',
  coupon: 'string? | null',
  createdAt: 'string',
  deadline: 'string?',
  email: 'string',
  level: 'string?',
  product: 'string',
  network: 'string?',
  user: 'string?',
  reviewer: 'string?',
  resume: 'object?',
  status: 'string',
  docInfos: 'object?',
  details: 'object?',
  completedAt: 'string?',
  deadlineDetail: 'string?'
});

class Order extends Document {
  constructor(source, options = {}) {
    super(source, {
      schema: OrderSchema,
      ...options
    });
  }
}

class Orders {
  constructor() {
    onAuthStateChanged(auth, user => {
      if (user && user.email) {
        this.orders.query = ref => {
          return user
            ? ref
                .where('user', '==', user.email)
                .where('product', '==', 'review')
            : null;
        };
        auth.currentUser.getIdTokenResult(true).then(async idToken => {
          this.rmsOrders.query = ref => {
            return idToken.claims.reviewer
              ? ref
                  .where('product', '==', 'reviewRms')
                  .where('network', '==', idToken.claims.reviewer)
              : null;
          };
        });
      }
    });
  }

  orders = new Collection('orders', {
    createDocument: (source, options) => new Order(source, options),
    query: ref => null,
    mode: 'off'
  });

  rmsOrders = new Collection('orders', {
    createDocument: (source, options) => new Order(source, options),
    query: ref => null,
    mode: 'off'
  });

  AllOrders = new Collection('orders', {
    createDocument: (source, options) => new Order(source, options),
    query: ref => null,
    mode: 'off'
  });

  _orderDoc = id => new Order(`orders/${id}`);

  updateOrder = (id, data) => {
    return this._orderDoc(id)
      .fetch()
      .then(order => order.update(data));
  };
}

export default createContext(new Orders());
