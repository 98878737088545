import { Collection, Document, getFirebase } from 'firestorter';
import { createContext } from 'react';
import shortid from 'shortid';
import { struct } from 'superstruct';
import { auth } from '../lib/firebase';
import { DEFAULT_TEMPLATE_ID } from '../lib/constants';
import moment from 'moment';
import { onAuthStateChanged } from 'firebase/auth';

export const ResumeSchema = struct({
    user: 'string',
    name: 'string',
    level: 'object?',
    field: 'object?',
    archived: 'boolean',
    locked: 'boolean',
    hide: 'boolean?',
    template: 'string',
    colors: 'object?',
    jobTitle: 'string?',
    jobDescription: 'string?',
    jobCompany: 'string?',
    keywords: 'string?',
    createdAt: 'string?',
    review: 'object?',
    reviewNote: 'object?',
    shareId: 'string?',
    shareActive: 'boolean?',
    dlCount: 'number?',
    owner: 'string?',
    reviewer: 'string?',
    profilePicture: 'string?',
    updatedAt: 'string?',
    crawledAt: 'string?',
    network: 'string?',
    coverLetter: 'string?',
    indexed: 'boolean',
    resumeHeight: 'number?',
    parserId: 'string?',
    rateParser: 'number?',
    madeFrom: 'string?',
    resumeReviewOrderId: 'string?',
    config: {
      chrono: 'object?',
      lineHeight: 'number',
      fontSize: 'number',
      showIcons: 'boolean',
      showPicture: 'boolean?',
      sections: {
        summary: 'object?',
        experience: {
          heading: 'string',
          index: 'number'
        },
        projects: {
          heading: 'string',
          index: 'number'
        },
        education: {
          heading: 'string',
          index: 'number'
        },
        certification: 'object?',
        coursework: {
          heading: 'string',
          index: 'number'
        },
        involvement: {
          heading: 'string',
          index: 'number'
        },
        skills: {
          heading: 'string',
          index: 'number'
        },
        contact: {
          heading: 'string',
          index: 'number'
        }
      }
    },
    data: {
      contact: {
        name: 'string?',
        email: 'string?',
        phone: 'string?',
        linkedin: 'string?',
        github: 'string?',
        website: 'string?',
        country: country => typeof country == 'object' || country == null,
        state: state => typeof state == 'object' || state == null,
        city: city => typeof city == 'object' || city == null,
        switchCountry: 'boolean?',
        switchState: 'boolean?',
        switchCity: 'boolean?'
      },
      summary: 'object?',
      experience: struct.dict([
        'string',
        {
          index: 'number',
          company: 'string?',
          location: 'string?',
          duration: 'string?',
          role: 'string?',
          description: 'string?',
          hide: 'boolean?'
        }
      ]),
      projects: struct.dict([
        'string',
        {
          index: 'number',
          title: 'string?',
          organization: 'string?',
          date: 'string?',
          description: 'string?',
          hide: 'boolean?'
        }
      ]),
      education: struct.dict([
        'string',
        {
          index: 'number',
          qualification: 'string?',
          institution: 'string?',
          location: 'string?',
          date: 'string?',
          minor: 'string?',
          gpa: 'string?',
          hide: 'boolean?'
        }
      ]),
      certification: 'object?',
      coursework: struct.dict([
        'string',
        {
          index: 'number?',
          department: 'string?',
          date: 'string?',
          name: 'string?',
          skill: 'string?',
          description: 'string?',
          hide: 'boolean?'
        }
      ]),
      involvement: struct.dict([
        'string',
        {
          index: 'number',
          organization: 'string?',
          location: 'string?',
          duration: 'string?',
          role: 'string?',
          description: 'string?',
          hide: 'boolean?'
        }
      ]),
      skills: struct.dict([
        'string',
        {
          index: 'number',
          skill: 'string',
          hide: 'boolean?'
        }
      ])
    }
  }),
  DEFAULTS = {
    archived: false,
    locked: false,
    hide: false,
    template: DEFAULT_TEMPLATE_ID,
    indexed: true,
    createdAt: moment().format('X'),
    shareActive: false,
    dlCount: 0,
    config: {
      chrono: {
        experience: true,
        projects: true,
        education: true,
        certification: true,
        coursework: true,
        involvement: true
      },
      lineHeight: 1.5,
      fontSize: 9,
      showIcons: true,
      sections: {
        summary: {
          heading: 'Summary',
          index: 0
        },
        experience: {
          heading: 'Experience',
          index: 1
        },
        projects: {
          heading: 'Projects',
          index: 2
        },
        education: {
          heading: 'Education',
          index: 3
        },
        certification: {
          heading: 'Certifications',
          index: 4
        },
        coursework: {
          heading: 'Coursework',
          index: 5
        },
        involvement: {
          heading: 'Involvement',
          index: 6
        },
        skills: {
          heading: 'Skills',
          index: 7
        },
        contact: {
          heading: 'Contact',
          index: 8
        }
      }
    },
    data: {
      contact: {},
      summary: {},
      experience: {},
      projects: {},
      education: {},
      certification: {},
      coursework: {},
      involvement: {},
      skills: {}
    }
  };

class Resume extends Document {
  constructor(source, options = {}) {
    super(source, {
      schema: ResumeSchema,
      ...options
    });
  }

  get hasUserData() {
    const { data } = this.data;

    return !Object.values(data).every(field => !Object.keys(field).length);
  }
}
class Resumes {
  constructor() {
    onAuthStateChanged(auth, user => {
      this.resumes.query = ref => {
        return user ? ref.where('user', '==', user.uid) : null;
      };
    });
  }

  resumes = new Collection('resumes', {
    createDocument: (source, options) => new Resume(source, options),
    query: ref => null,
    mode: 'on'
  });

  resume = new Collection('resumes', {
    createDocument: (source, options) => new Resume(source, options),
    query: ref => null,
    mode: 'off'
  });

  _resumeDoc = id => new Resume(`resumes/${id}`);

  getResume = resumeId => {
    return this.resumes.docs.find(({ id, data }) => {
      return id === resumeId && !data.hide;
    });
  };

  addResume = async data => {
    return await this.resume
      .add({
        user: auth.currentUser.uid,
        ...DEFAULTS,
        ...data,
        data: {
          ...DEFAULTS.data,
          ...data.data
        },
        createdAt: moment().format('X'),
        updatedAt: moment().format('X')
      })
      .then(data => {
        return data;
      })
      .catch(err => console.log(err));
  };

  addWritingResume = data => {
    return this.resumes
      .add({
        user: auth.currentUser.uid,
        ...DEFAULTS,
        ...data,
        createdAt: moment().format('X'),
        updatedAt: moment().format('X'),
        hide: true
      })
      .then(res => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'resume-created',
          plan: 'Pro Free Trial'
        });
        return res;
      });
  };

  updateResume = async (id, data) => {
    return await this._resumeDoc(id)
      .fetch()
      .then(
        async resume =>
          await resume.update({
            ...data,
            updatedAt: moment().format('X')
          })
      );
  };

  updateResumeHeight = async id => {
    const perfectSize = 950; //1004
    const resumeEl = document.getElementById('resumeWrapper');
    const resumeHeight = resumeEl
      ? document.getElementById('resumeWrapper').clientHeight
      : 0;
    const factor = 1;
    let sizeRate =
      Math.floor(Math.round((resumeHeight / perfectSize) * 100) / factor) *
      factor;

    return await this._resumeDoc(id)
      .fetch()
      .then(
        async resume =>
          await resume
            .update({
              resumeHeight: sizeRate
            })
            .then(() => {
              return sizeRate;
            })
      );
  };

  updateResumeItem = async (resumeId, field, item, id) => {
    const ifNoId = shortid.generate();
    const itemKey = id
      ? id === field
        ? `data.${field}`
        : `data.${field}.${id}`
      : `data.${field}.${ifNoId}`;

    const { [field]: list } = await this.getResume(resumeId).data.data,
      highestIndex =
        list && Object.keys(list).length
          ? Math.max(...Object.values(list).map(item => item.index))
          : -1;

    return await this._resumeDoc(resumeId)
      .fetch()
      .then(
        async resume =>
          await resume
            .update({
              [itemKey]: { ...item, ...(!id && { index: highestIndex + 1 }) },
              updatedAt: moment().format('X')
            })
            .then(() => {
              return id || ifNoId;
            })
      );
  };

  editResumeItem = async (resume, resumeId, field, item, id) => {
    const itemKey = id
      ? id === field
        ? `data.${field}`
        : `data.${field}.${id}`
      : `data.${field}.${shortid.generate()}`;
    return resume.update({
      [itemKey]: { ...item },
      updatedAt: moment().format('X')
    });
  };

  removeResumeRating = resumeId => {
    const { delete: deleteField } = getFirebase().firestore.FieldValue;

    return this._resumeDoc(resumeId)
      .fetch()
      .then(resume =>
        resume.update({
          rateParser: deleteField(),
          updatedAt: moment().format('X')
        })
      );
  };

  removeResumeItem = (resumeId, field, itemId) => {
    const { delete: deleteField } = getFirebase().firestore.FieldValue,
      itemKey = `data.${field}.${itemId}`;

    return this._resumeDoc(resumeId)
      .fetch()
      .then(resume =>
        resume.update({
          [itemKey]: deleteField(),
          updatedAt: moment().format('X')
        })
      );
  };

  deleteResume = id => this._resumeDoc(id).delete();
}

export default createContext(new Resumes());
