import { observer } from 'mobx-react-lite';
import React, { useContext, useState, useEffect } from 'react';
import RmsStore from '../../stores/Rms';
import './styles.module.css';
import moment from 'moment';
import SplashScreen from '../../components/SplashScreen';
import { useTranslation } from 'react-i18next';

/**
 * Rms container
 * Entrypoint for Rms HOC application
 */
const getCookie = cname => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const Rms = observer(({ className, children }) => {
  //todo change before sending to prod
  //   const domainSplit = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.');

  const domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
  const subdomain = domain.split('.')[0];
  const fullDomain = domain !== 'app.rezi.io' || domain !== 'app.rezi.ai';

  const { i18n } = useTranslation();

  const { rms, getRms, store } = useContext(RmsStore);
  const rmsIndex = store.rmsIndex;
  const pingRms = (domain, hasCookie) => {
    if (!hasCookie) {
      const d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + d.toUTCString();
      document.cookie = 'wlrezi=' + domain + ';' + expires + ';path=/';

      const data = JSON.stringify({
        rms: domain,
        type: 'vu'
      });
      return window.fetch(`${process.env.PROD_API}/pingStat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Content-Length': data.length
        },
        body: data
      });
    }
  };

  const hydrateActiveRms = async owner => {
    const rmsInfos = getRms(subdomain, fullDomain ? domain : '');
    rmsInfos
      .then(info => {
        if (info && info.data && info.data.active && info.data.title) {
          store.handleRmsElement(
            owner
              ? { ...info.data, title: info.data.title + ' [rms-owner]' }
              : { ...info.data }
          );
          if (info.data.active && info.data.domain) {
            pingRms(info.data.domain, getCookie('wlrezi'));
          }
        } else if (
          info.docs &&
          info.docs.length > 0 &&
          info.docs[0] &&
          info.docs[0].data &&
          info.docs[0].data.active &&
          info.docs[0].data.title
        ) {
          store.handleRms(true);
          store.handleRmsElement(
            owner
              ? {
                  ...info.docs[0].data,
                  title: info.docs[0].data.title + ' [rms-owner]'
                }
              : { ...info.docs[0].data }
          );
          if (info.docs[0].data.active && info.docs[0].data.domain) {
            pingRms(info.docs[0].data.domain, getCookie('wlrezi'));
          }
          if (info.docs[0].data.forceCV) {
            i18n.changeLanguage('en-GB');
          }
        } else {
          if (owner) {
            store.handleRmsElement({ title: ' [rms-owner]' });
          }
        }
        store.handleLoadRms(false);
      })
      .catch(() => {
        store.handleLoadRms(false);
      });
  };

  const saveFingerPrint = async () => {
    setTimeout(() => {
      const win =
        document.getElementById('ifr') &&
        document.getElementById('ifr').contentWindow;
      if (win) {
        win.postMessage(window.location.hostname, 'https://app.rezi.ai');
      }
    }, 5000);
  };

  const initRms = () => {
    const previewRms =
      rms.hasDocs && rms.docs[rmsIndex] ? rms.docs[rmsIndex].data : null;

    if (previewRms && previewRms.previewSince) {
      if (
        Number(previewRms.previewSince) + 1800 >
        Number(moment().format('X'))
      ) {
        store.handleLoadRms(true);
        const rmsInfoToHydrate = {
          ...previewRms,
          logo: { ...previewRms.logo },
          aside: { ...previewRms.aside },
          title: previewRms.title + ' [rms-owner]',
          active: true
        };
        if (previewRms.forceCV) {
          i18n.changeLanguage('en-GB');
        }
        store.handleRmsElement({ ...rmsInfoToHydrate });
        store.handleRms(true);
        store.handleLoadRms(false);
        pingRms(previewRms.domain, getCookie('wlrezi'));
      } else {
        hydrateActiveRms(true);
      }
    } else {
      store.handleRms(false);
      store.handleLoadRms(true);
      hydrateActiveRms();
    }
  };

  useEffect(() => {
    initRms();
  }, [rmsIndex, rms.hasDocs && rms.docs[rmsIndex] && rms.docs[rmsIndex].data]);
  return (
    <div className={className || ''}>
      <div style={{ position: 'relative' }}>
        {/*{process.env.GENERAL_ENV === 'production' &&*/}
        {/*  window.location.hostname !== 'app.rezi.ai' &&*/}
        {/*  store.rmsHasInfos && (*/}
        {/*    <iframe*/}
        {/*      style={{ display: 'none' }}*/}
        {/*      src="https://app.rezi.ai/fp?nc=true"*/}
        {/*      id="ifr"*/}
        {/*      onLoad={() => saveFingerPrint(store.rmsElement)}*/}
        {/*    />*/}
        {/*  )}*/}
        <div className={`rmsLoadHide ${store.loadRms ? '' : 'deactive'}`}>
          <SplashScreen />
        </div>
      </div>
    </div>
  );
});

export default Rms;
