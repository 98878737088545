import { auth } from '../lib/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { FUNCTIONS_ENDPOINT } from '../lib/constants';
import moment from 'moment';

export async function trackingSignup(user) {
  if (typeof wcs !== undefined && wcs) {
    //NAVER TRACKING
    var _nasa = {};
    _nasa['cnv'] = wcs.cnv('2', '1');
  }
  if (user && user.providerData && user.providerData[0]) {
    //GA TRACKING
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'user-signup-success',
      method:
        user.providerData.length > 0
          ? user.providerData[0].providerId === 'password'
            ? 'onsite'
            : user.providerData[0].providerId.split('.')[0]
          : 'customToken',
      'user-id': user.uid,
      value: 1
    });
    //GA4 tracking
    window.dataLayer.push({
      event: 'sign_up',
      method:
        user.providerData.length > 0
          ? user.providerData[0].providerId === 'password'
            ? 'onsite'
            : user.providerData[0].providerId.split('.')[0]
          : 'customToken'
    });
    //MIXPANEL TRACKING
    if (mixpanel) {
      mixpanel.track('user-signup-success', {
        method:
          user.providerData.length > 0
            ? user.providerData[0].providerId === 'password'
              ? 'onsite'
              : user.providerData[0].providerId.split('.')[0]
            : 'customToken'
      });
    }

    const eventId = user.id + moment().format('X');

    if (window.location.href.includes('app.rezi.ai')) {
      if (typeof fbq !== 'undefined') {
        fbq(
          'track',
          'CompleteRegistration',
          {
            content_name: 'signup',
            status: true
          },
          { eventID: eventId }
        );
      }
      auth.currentUser.getIdTokenResult(true).then(async idToken => {
        const headers = new Headers();
        headers.append('Content-Type', `application/json`);
        headers.append('Authorization', `Bearer ${idToken.token}`);
        await window.fetch(`${process.env.PROD_API}/fbConvApiSignup`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            eventSource: window.location.href,
            email: user.email,
            user: user.id,
            eventId: eventId
          })
        });
      });
      if (typeof window.lintrk !== 'undefined') {
        window.lintrk('track', { conversion_id: 4767868 });
      }
    }
    if (user.email) {
      window
        .fetch(`${FUNCTIONS_ENDPOINT}/intercom/hash`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: user.email
          })
        })
        .then(res => res.text())
        .then(resp => {
          if (resp && (resp[0] === '[' || resp[0] === '{')) {
            window.intercomSettings = {
              app_id: 'jv0knjo3',
              name: user.displayName ? user.displayName : '',
              email: user.email,
              created_at: user.createdAt
                ? user.createdAt
                : moment().format('X'),
              user_hash: JSON.parse(resp).hash
            };
          }
        });
    }
  }
}

export async function trackingTrial(user) {
  if (typeof wcs !== undefined) {
    //NAVER TRACKING
    var _nasa = {};
    _nasa['cnv'] = wcs.cnv('5', '1');
  }
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'start-trial',
      value: 1
    });
    //GA4 tracking
    window.dataLayer.push({
      event: 'tutorial_begin'
    });
  }
  const eventId = user.id + moment().format('X');

  if (window.location.href.includes('app.rezi.ai')) {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'StartTrial', {}, { eventID: eventId });
    }
    auth.currentUser.getIdTokenResult(true).then(async idToken => {
      const headers = new Headers();
      headers.append('Content-Type', `application/json`);
      headers.append('Authorization', `Bearer ${idToken.token}`);
      await window.fetch(`${process.env.PROD_API}/fbConvApiTrial`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          email: user.data.email,
          eventSource: window.location.href,
          user: user.id,
          eventId: eventId
        })
      });
    });
    if (typeof window.lintrk !== 'undefined') {
      window.lintrk('track', { conversion_id: 4767876 });
    }
  }
}

export async function trackingPurchase(details) {
  const {
    price,
    id,
    userId,
    email,
    productName,
    category,
    variant,
    unitPrice,
    coupon,
    productId
  } = details;

  if (typeof wcs !== undefined) {
    //NAVER TRACKING
    var _nasa = {};
    _nasa['cnv'] = wcs.cnv('1', price);
  }

  //GA TRACKING
  window.dataLayer.push({
    event: 'purchase',
    revenue: Number(price),
    ecommerce: {
      purchase: {
        actionField: {
          id: id,
          revenue: price,
          tax: '0.00',
          shipping: '0.00',
          coupon: coupon || ''
        },
        products: [
          {
            name: productName,
            id: productId,
            price: unitPrice + '.00',
            category: category,
            variant: variant,
            quantity: 1
          }
        ]
      }
    },
    ga4: {
      transaction_id: id,
      currency: 'USD',
      value: Number(price),
      tax: 0.0,
      shipping: 0.0,
      coupon: coupon || '',
      items: [
        {
          item_id: productId,
          item_name: productName,
          coupon: coupon || '',
          currency: 'USD',
          item_category: category,
          item_variant: variant,
          price: Number(price),
          quantity: 1
        }
      ]
    }
  });
  const eventId = productId + userId + moment().format('X');

  if (mixpanel) {
    mixpanel.track('purchase', {
      revenue: price,
      tax: '0.00',
      shipping: '0.00',
      coupon: coupon || '',
      name: productName,
      id: productId,
      price: unitPrice + '.00',
      category: category,
      variant: variant
    });
  }
  if (window.location.href.includes('app.rezi.ai')) {
    if (typeof fbq !== 'undefined') {
      fbq(
        'track',
        'Purchase',
        {
          contents: [
            {
              id: productId,
              quantity: 1
            }
          ],
          content_type: 'product',
          content_name: productName,
          currency: 'USD',
          num_items: 1,
          value: Number(price)
        },
        { eventID: eventId }
      );
    }
    auth.currentUser.getIdTokenResult(true).then(async idToken => {
      const headers = new Headers();
      headers.append('Content-Type', `application/json`);
      headers.append('Authorization', `Bearer ${idToken.token}`);
      await window.fetch(`${process.env.PROD_API}/fbConvApiPurchase`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          email: email,
          eventSource: window.location.href,
          amount: Number(price),
          sku: productId,
          user: userId,
          eventId: eventId
        })
      });
    });

    if (typeof window.lintrk !== 'undefined') {
      window.lintrk('track', { conversion_id: 4767884 });
    }
  }
}

export async function trackingProductEvent(eventName, detail) {
  if (mixpanel) {
    if (detail) {
      mixpanel.track(eventName, {
        ...detail
      });
    } else {
      mixpanel.track(eventName);
    }
  }
}

export function trackingAddToCard(
  productId,
  productName,
  billed,
  amount,
  customer
) {
  window.dataLayer = window.dataLayer ? window.dataLayer : [];
  if (window.dataLayer && window.dataLayer.length > 0) {
    for (let i = 0; i < window.dataLayer.length; i += 1) {
      if (
        window.dataLayer[i].event === 'addToCart' &&
        window.dataLayer[i].ecommerce.add.products.length > 0
      ) {
        window.dataLayer.splice(i, i);
      }
    }
  }
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'USD',
      add: {
        products: [
          {
            name: `${billed} Rezi ${productName}`,
            id: productId,
            price: `${amount}.00`,
            category: productName,
            variant: billed,
            quantity: 1
          }
        ]
      }
    }
  });
  if (mixpanel) {
    mixpanel.track('addToCart', {
      name: `Rezi ${productName} - ${billed}`,
      id: productId,
      price: `${amount}.00`,
      category: productName,
      variant: billed
    });
  }

  if (customer && customer.email) {
    window.fetch(`${FUNCTIONS_ENDPOINT}/intercom/user/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: customer.email,
        custom_attributes: {
          abandoned_cart_item_name: `Rezi ${productName} ${billed}`,
          last_addtocart_at: moment().format('X')
        }
      })
    });
  }
}

export function initiateCheckout(productId, productName, billed, amount) {
  if (window.location.href.includes('app.rezi.ai')) {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'InitiateCheckout', {
        contents: [
          {
            id: productId,
            quantity: 1
          }
        ],
        content_type: 'product',
        content_ids: [productId],
        content_category: 'Rezi plan',
        currency: 'USD',
        num_items: 1,
        value: amount
      });
    }
    if (typeof window.lintrk !== 'undefined') {
      window.lintrk('track', { conversion_id: 4767900 });
    }
  }
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'initiate-checkout'
    });
  }
}

// type : update_contact, fail_induce
export const trackingSendgrid = async (type, postData) => {
  onAuthStateChanged(auth, async user => {
    await auth.currentUser.getIdTokenResult(true).then(async idToken => {
      let data;
      if (!postData) {
        data = JSON.stringify({
          email: user.email,
          user: user.uid
        });
      } else {
        data = JSON.stringify({
          email: user.email,
          user: user.uid,
          ...postData
        });
      }

      const headers = new Headers();
      headers.append('Content-Type', `application/json`);
      headers.append('Access-Control-Allow-Origin', `*`);
      headers.append('Content-Length', data.length);
      headers.append('Authorization', `Bearer ${idToken.token}`);
      window
        .fetch(`${process.env.PROD_API}/sendGridTracking?type=` + type, {
          mode: 'cors',
          method: 'POST',
          headers: headers,
          body: data
        })
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    });
  });
};
