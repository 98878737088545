import { auth } from '../lib/firebase';

export const fetching = async (data, endpoint) => {
  return new Promise(async (resolve, reject) => {
    await auth.currentUser.getIdTokenResult(true).then(async idToken => {
      const headers = new Headers();

      headers.append('Content-Type', `application/json`);
      headers.append('Content-Length', data.length);
      headers.append('Authorization', `Bearer ${idToken.token}`);
      await window
        .fetch(`${process.env.PROD_API}/${endpoint}`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => {
          if (!res) return notify('fail');
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  });
};
