import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react-lite';
import './styles.module.css';
import Modal from '../../Modal';
import { useTranslation } from 'react-i18next';
import Switch from '../../Switch';
import Button from '../../Button';
import Coupon from '../Coupon';
import PriceDisplay from '../PriceDisplay';
import PlanModalRow from '../PlanModalRow';
import Billing from '../../../stores/Billing';
import CheckoutForm from '../CheckoutForm';
import { fetching } from '../../../apis/functions';
import User from '../../../stores/User';
import { auth } from '../../../lib/firebase';
import RmsStore from '../../../stores/Rms';
import AITokenCounter from '../../AITokenCounter';
import { notify } from '../../Notification';
import {
  trackingSendgrid,
  initiateCheckout,
  trackingAddToCard,
  trackingProductEvent
} from '../../../utils/tracking';
import debounce from '../../../utils/debounce';
import { calculPriceToDisplay } from '../PriceDisplay/action';
import ReactPlayer from 'react-player';
import lsTest from '../../../utils/lsAvailable';

/**
 * PlanModal component
 */
export default observer(PlanModal);
function PlanModal({
  open,
  onClose,
  planId,
  billed,
  highlight,
  forceCoupon = null,
  success = false,
  isForced = false,
  resumeReview = null
}) {
  const { t } = useTranslation(['translation', 'rms']);
  const { user } = useContext(User);
  const billing = useContext(Billing);
  const { customer } = billing;
  const { store } = useContext(RmsStore);
  const isAutocompleteCouponRms =
    store.isRms &&
    store.rmsElement &&
    store.rmsElement.dynamicPromotion &&
    store.rmsElement.dynamicPromotion.autocomplete;
  const autocompleteCouponRms = isAutocompleteCouponRms
    ? store.rmsElement.dynamicPromotion.coupons.find(
        coupon => coupon.active === true
      )
    : null;
  const hideSubscription =
    (store.isRms && store.rmsElement && store.rmsElement.hideSubscription) ||
    resumeReview;
  const hideAiCredits = resumeReview;
  const hideSubsPlan =
    store.isRms && store.rmsElement && store.rmsElement.hideSubsPlan;
  const [step, setStep] = useState(success ? 2 : 0);
  const [state, setState] = useState({
    billed: billed || 'Monthly',
    coupon:
      autocompleteCouponRms && autocompleteCouponRms.id
        ? { id: autocompleteCouponRms.id }
        : typeof forceCoupon === 'string'
        ? { id: forceCoupon }
        : billing.forceCoupon
        ? billing.forceCoupon
        : {}
  });
  const [isBusy, setIsBusy] = useState(false);
  const plan = require('../price.json');
  const [planCheck, setPlanCheck] = useState(
    planId && plan.getPriceLabel[planId] && !hideSubscription && !hideSubsPlan
      ? plan.getPriceLabel[planId]
      : 'Free'
  );
  const [forceDetailOpen, setForceDetailOpen] = useState(
    planId && plan.getPriceLabel[planId] ? plan.getPriceLabel[planId] : null
  );
  const list = hideSubscription
    ? []
    : hideSubsPlan
    ? ['Lifetime']
    : ['Basic', 'Pro', 'Lifetime'];

  const credits = {
    price: 10,
    duration: 'once',
    value: 'price_1K1nHdEd6K02KvTI27zrBTZb',
    label: 'credit'
  };
  const get = planName => plan.priceTable[state.billed][planName];
  const [clientSecret, setClientSecret] = useState('');
  const handleUpgrade = async () => {
    setStep(2);
  };

  const handleModifyOrder = () => {
    if (!isBusy) {
      setStep(step - 1);
      setClientSecret('');
    }
  };

  const handleChoosePayment = async () => {
    const freePlan = 'prod_FfrohAFnsPcvLm'; //todo: free plan "prod_FfrohAFnsPcvLm";

    initiateCheckout(
      resumeReview ? resumeReview.priceId : get(planCheck).value,
      resumeReview ? 'Resume review' : get(planCheck).label,
      resumeReview ? 'once' : state.billed,
      calculPriceToDisplay(
        state.coupon ? state.coupon : '',
        state.billed,
        get(planCheck),
        !resumeReview,
        true,
        state.addCredit,
        credits,
        resumeReview
      )
    );

    if (
      get(planCheck).value === freePlan &&
      !state.addCredit &&
      !resumeReview
    ) {
      //No plan selected
      notify(t('modal_plan_payment.no_selection'));
      return;
    }

    if (
      resumeReview &&
      calculPriceToDisplay(
        state.coupon ? state.coupon : '',
        state.billed,
        get(planCheck),
        !resumeReview,
        true,
        state.addCredit,
        credits,
        resumeReview
      ) === '0.00'
    ) {
      //Resume Review with free coupon
      setIsBusy(true);
      return billing.resumeReview.onInit('paid');
    }

    setIsBusy(true);
    if (
      auth.currentUser &&
      planCheck &&
      (get(planCheck).value ||
        (get(planCheck).value === freePlan && !state.addCredit))
    ) {
      let data = {
        user: user.id,
        customerId: customer.id,
        network: (store.isRms && store.rmsElement.domain) || '',
        coupon: (state.coupon && state.coupon.id) || '',
        planId: planCheck === 'Free' ? '' : get(planCheck).value, //todo: replace test product
        addCredit: state.addCredit,
        resumeReview: resumeReview
      };

      if (planCheck === 'Free' && (state.addCredit || resumeReview)) {
        data.items = [
          {
            id: state.addCredit
              ? 'price_1K1nHdEd6K02KvTI27zrBTZb'
              : resumeReview.priceId
          }
        ];
        fetching(data, 'stripe-createPaymentIntent')
          .then(data => {
            setClientSecret(data.clientSecret);
            setIsBusy(false);
          })
          .catch(() => {
            notify('Error. Please try again or contact administrator');
            setStep(0);
            setIsBusy(false);
            if (mixpanel) {
              mixpanel.track('purchase-error', {
                error: t('modal_plan_checkout.error_miss_card_info')
              });
            }
          });
      } else {
        fetching(data, 'stripe-createSubscription')
          .then(data => {
            if (lsTest() === true) {
              window.localStorage.setItem(
                'rezi-purchase',
                JSON.stringify({
                  price: calculPriceToDisplay(
                    state.coupon ? state.coupon : '',
                    state.billed,
                    get(planCheck),
                    true,
                    true,
                    state.addCredit,
                    credits,
                    resumeReview
                  ),
                  userId: user.id,
                  email:
                    user && user.data && user.data.email ? user.data.email : '',
                  productName: get(planCheck).label,
                  category: get(planCheck).label,
                  variant: state.billed,
                  unitPrice: get(planCheck).price,
                  coupon: state.coupon ? state.coupon.id : '',
                  productId: get(planCheck).value
                })
              );
            }

            if (data.status === 'active') {
              setIsBusy(false);
              document.location.href =
                window.location.href +
                `?step=payment-success&payment_intent=${data.payment_intent}&redirect_status=succeeded`;
            } else {
              setIsBusy(false);
              setClientSecret(data.clientSecret);
            }
          })
          .catch(() => {
            setIsBusy(false);
            notify('Error. Please try again or contact administrator');
            setStep(0);
            if (mixpanel) {
              mixpanel.track('purchase-error', {
                error: t('modal_plan_checkout.error_miss_card_info')
              });
            }
          });
      }
    }
    setStep(step + 1);
    setForceDetailOpen('');
  };

  const appearance = {
    theme: 'flat'
  };
  const options = {
    clientSecret,
    appearance
  };

  useEffect(() => {
    if (success) {
      handleUpgrade();
    }
  }, [success]);

  const debounceTracking = useCallback(
    debounce((event, details) => {
      trackingProductEvent(event, details);
    }, 1000),
    []
  );

  useEffect(() => {
    if (open) {
      debounceTracking('openCheckout', {
        forced: isForced,
        highlight: highlight
      });
    }
  }, [open, isForced]);

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!isBusy) {
          trackingProductEvent('close-modal-upgrade');
          setStep(0);
          trackingSendgrid('fail_induce');
          billing.resumeReview = null;
          onClose();
        }
      }}
      className="plan-modal"
      customOverlayClassName="checkoutmodal"
    >
      <header styleName="header">
        {!resumeReview
          ? t('modal_plan_payment.modal_title')
          : t('modal_plan_payment.modal_review_title')}
      </header>
      <main>
        <div style={{ marginLeft: step === 0 ? '0' : `-${step}00%` }}>
          <section styleName={step !== 0 ? 'collapse' : ''}>
            {store.isRms &&
              store.rmsElement &&
              store.rmsElement.customUpgradeContent && (
                <div>
                  <div styleName="plan-detail-wrapper">
                    <span> Upgrade to unlock more features</span>
                    <a href={store.rmsElement.customUpgradeContent}>
                      See options{' '}
                      <i
                        className="fas fa-angle-right"
                        style={{
                          fontSize: '13px',
                          padding: '2px 0px 0px 10px'
                        }}
                      />
                    </a>
                  </div>
                </div>
              )}
            {!hideSubscription && (
              <>
                <div styleName="section-title">
                  {t('modal_plan_payment.plans')}
                </div>
                <div styleName="switch-plan-recurrent">
                  <span>{t('modal_plan_payment.monthly')}</span>
                  <Switch
                    checked={state.billed === 'Quarterly'}
                    onClick={e => e.stopPropagation()}
                    onChange={() => {
                      debounceTracking('switchBilled', {
                        billed:
                          state.billed === 'Quarterly' ? 'Monthly' : 'Quarterly'
                      });
                      trackingAddToCard(
                        get(planCheck).value,
                        get(planCheck).label,
                        state.billed === 'Quarterly' ? 'Monthly' : 'Quarterly',
                        get(planCheck).price,
                        customer
                      );
                      setState({
                        ...state,
                        billed:
                          state.billed === 'Monthly' ? 'Quarterly' : 'Monthly'
                      });
                    }}
                  />
                  <span>{t('modal_plan_payment.quarterly')}</span>
                </div>
              </>
            )}

            <div styleName="plan-wrapper">
              {list.map(plan => (
                <PlanModalRow
                  key={get(plan).label}
                  plan={plan}
                  highlight={highlight}
                  planCheck={planCheck}
                  setPlanCheck={plan => {
                    debounceTracking('selectPlan', {
                      select: plan === 'Free' ? 'unselect' : 'select',
                      product: plan,
                      billing: state.billed
                    });
                    trackingAddToCard(
                      get(plan).value,
                      get(plan).label,
                      state.billed,
                      get(plan).price,
                      customer
                    );
                    setPlanCheck(plan);
                  }}
                  forceDetailOpen={forceDetailOpen}
                  credits={false}
                  coupon={state.coupon}
                  billed={state.billed}
                />
              ))}
            </div>
            {!hideAiCredits && (
              <>
                <div styleName="section-title">
                  {t('modal_plan_payment.ai_credits')}
                  <span styleName="aiTokenCounter">
                    <AITokenCounter onlyCounterDisplay={true} />{' '}
                    {t('modal_plan_payment.credits')}
                  </span>
                </div>
                <div styleName="plan-wrapper">
                  <PlanModalRow
                    plan={credits}
                    planCheck={planCheck}
                    setPlanCheck={setPlanCheck}
                    forceDetailOpen={forceDetailOpen}
                    credits={credits}
                    addCredit={state.addCredit}
                    setAddCredit={addCredit => {
                      debounceTracking('selectPlan', {
                        select: addCredit ? 'select' : 'unselect',
                        product: 'credit'
                      });
                      trackingAddToCard(
                        credits.value,
                        credits.label,
                        '',
                        credits.price,
                        customer
                      );
                      setState({ ...state, addCredit: addCredit });
                    }}
                    coupon={state.coupon}
                    billed={state.billed}
                  />
                </div>
              </>
            )}
            {billing.resumeReview && (
              <>
                {store.isRms &&
                store.rmsElement &&
                store.rmsElement.reviewCustom &&
                store.rmsElement.reviewCustom.content ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: store.rmsElement.reviewCustom.content
                    }}
                  />
                ) : (
                  <ReactPlayer
                    url={
                      store.isRms &&
                      store.rmsElement &&
                      store.rmsElement.reviewCustom &&
                      store.isRms &&
                      store.rmsElement &&
                      store.rmsElement.reviewCustom.video
                        ? store.rmsElement.reviewCustom.video
                        : `https://rezi.wistia.com/medias/89eggt3ick`
                    }
                    styleName="video-module"
                  />
                )}
              </>
            )}

            <Coupon
              forceCoupon={forceCoupon}
              openCoupon={false}
              product={planCheck && get(planCheck).value}
              duration={planCheck && get(planCheck).duration}
              handleCoupon={coupon => setState({ ...state, coupon: coupon })}
              handleUpgrade={handleUpgrade}
            />
          </section>
          <section>
            {clientSecret && billing.stripePromise ? (
              <Elements options={options} stripe={billing.stripePromise}>
                <CheckoutForm
                  purchaseDetail={{
                    price: calculPriceToDisplay(
                      state.coupon ? state.coupon : '',
                      state.billed,
                      get(planCheck),
                      !resumeReview,
                      true,
                      state.addCredit,
                      credits,
                      resumeReview
                    ),
                    userId: user.id,
                    email:
                      user && user.data && user.data.email
                        ? user.data.email
                        : '',
                    productName: resumeReview
                      ? 'Resume Review'
                      : get(planCheck).label,
                    category: resumeReview
                      ? 'Resume Review'
                      : get(planCheck).label,
                    variant: state.billed,
                    unitPrice: resumeReview
                      ? resumeReview.price
                      : get(planCheck).price,
                    coupon: state.coupon ? state.coupon.id : '',
                    productId: resumeReview
                      ? resumeReview.priceId
                      : get(planCheck).value
                  }}
                />
              </Elements>
            ) : (
              <div styleName="ghostPayment">
                <span>{t('modal_plan_payment.payment_prepare')}</span>
                <div>
                  <div />
                  <div styleName="half" />
                  <div styleName="half" />
                  <div />
                </div>
              </div>
            )}
          </section>
          <section styleName="success">
            <h1>{t('modal_plan_payment.success')}</h1>
            <div styleName="success-anim">
              <i className="fad fa-badge-check" />
            </div>
            <p>{t('modal_plan_payment.success_sub')}</p>
          </section>
        </div>
      </main>
      {step !== 2 && (
        <footer>
          <div styleName="total-wrapper">
            <span>{t('modal_plan_payment.total_due')}</span>
            <span styleName="total">
              <PriceDisplay
                plan={planCheck}
                coupon={state.coupon}
                billed={state.billed}
                forceFloat={true}
                addCredit={state.addCredit}
                subscriptionBase={true}
                resumeReview={resumeReview}
              />
            </span>
          </div>
          <div styleName="purchase-wrapper">
            {step === 1 && (
              <Button
                onClick={handleModifyOrder}
                style={{
                  background: 'var(--color-grey-100)',
                  color: 'var(--color-grey-500)'
                }}
                busy={isBusy}
              >
                <i className="fas fa-angle-left" style={{ fontSize: '13px' }} />
              </Button>
            )}
            {step === 0 && (
              <Button onClick={handleChoosePayment} busy={isBusy}>
                {customer &&
                ((customer.invoice_settings &&
                  customer.invoice_settings.default_payment_method &&
                  customer.invoice_settings.default_payment_method.length >
                    0) ||
                  (customer.default_source &&
                    customer.default_source.length > 0)) &&
                planCheck !== 'Free' ? (
                  <>{t('modal_plan_payment.pay_now')}</>
                ) : (
                  <>
                    {t('modal_plan_payment.choose_payment')}{' '}
                    <i
                      className="fas fa-angle-right"
                      style={{ fontSize: '13px', padding: '2px 0 0 10px' }}
                    />
                  </>
                )}
              </Button>
            )}
          </div>
        </footer>
      )}
    </Modal>
  );
}
