import React from 'react';
import './styles.module.css';

/**
 * Radio component
 * Radio form input
 * @property {string} label
 */
export default function Radio({
  label = '',
  onChange = () => null,
  value = false,
  className,
  ...attrs
}) {
  return (
    <label styleName="checkbox" className={className || ''} {...attrs}>
      <input type="checkbox" onChange={onChange} value={value} hidden />
      <div styleName="check" data-checked={value} />
      <span styleName="label">{label}</span>
    </label>
  );
}
