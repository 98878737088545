import React from 'react';
import ReactSelect from 'react-select/creatable';
import Field from '../Field';
import '../Select/styles.module.css';
let data = require('./country.json');

/**
 * SelectCountry component
 * SelectCountry form input
 * @property {array} options SelectCountry options
 * @properties Inherited from Field
 */
export default function SelectCountry({ className, inputClassName, ...attrs }) {
  const countryList = data.country.sort().map( c => {
    return {
      value: c.country_name,
      label: c.country_name
    };
  });
  return (
    <Field
      styleName="select"
      InputElement={ReactSelect}
      options={countryList}
      classNamePrefix="select"
      className={className || ''}
      inputClassName={inputClassName || ''}
      {...attrs}
    />
  );
}
