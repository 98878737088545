import { observable } from 'mobx';
import { createContext } from 'react';
import { notify } from '../components/Notification';
import { FUNCTIONS_ENDPOINT, NOTIFICATIONS } from '../lib/constants';
import { auth } from '../lib/firebase';
import { User } from './User';
import { trackingSendgrid } from '../utils/tracking';
import 'whatwg-fetch';
import * as Sentry from '@sentry/browser';
import { onAuthStateChanged } from 'firebase/auth';
import { getCookie } from '../utils/cookie';
import { loadStripe } from '@stripe/stripe-js';

const forceDowngrade = async (customer, userId) => {
  return await auth.currentUser.getIdTokenResult(true).then(async idToken => {
    return await window
      .fetch(`${FUNCTIONS_ENDPOINT}/stripe/plans/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customer: customer.id,
          plan: 'prod_FfrohAFnsPcvLm',
          userId: userId,
          idToken: idToken.token,
          forceDowngrade: true
        })
      })
      .then(res => {
        return res.json();
      })
      .catch(err => {
        console.log(err);
      });
  });
};

function checkSubProduct(customer) {
  let index = customer.subscriptions.data.length - 1;
  if (customer.subscriptions.data.length > 0) {
    customer.subscriptions.data.map((sub, i) => {
      if (
        !(
          sub.items.data[0] &&
          sub.items.data[0].plan.metadata &&
          sub.items.data[0].plan.metadata.type &&
          sub.items.data[0].plan.metadata.type === 'rms'
        ) &&
        sub.status === 'active' &&
        sub.plan.id !== 'plan_FfroNQtjGkZjYS' //todo:change to free product
      ) {
        index = i;
      }
    });
  }
  return index;
}

async function fetchCustomer(id, userId) {
  try {
    const customer = await window
      .fetch(`${FUNCTIONS_ENDPOINT}/stripe/customer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id
        })
      })
      .then(res => res.json());
    let pastDue = false;
    const indexOfSubProduct = checkSubProduct(customer);

    if (
      userId &&
      customer.subscriptions.data[indexOfSubProduct] &&
      customer.subscriptions.data[indexOfSubProduct].status !== 'active'
    ) {
      await forceDowngrade(customer, userId);
      pastDue = true;
    }

    return {
      ...customer,
      pastDue: pastDue,
      plan:
        customer.subscriptions.data.length < 1 ||
        (customer.subscriptions.data[indexOfSubProduct] &&
          customer.subscriptions.data[indexOfSubProduct].status !== 'active')
          ? 'prod_FfrohAFnsPcvLm'
          : customer.subscriptions.data[indexOfSubProduct].plan.product
    };
  } catch (err) {
    notify(NOTIFICATIONS.error);
    return {};
  }
}

const store = observable({
  templates: [],
  plans: [
    {
      id: 'prod_FfrohAFnsPcvLm',
      object: 'product',
      active: true,
      attributes: [],
      created: 1566541008,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'plan',
        features: '1 Resume',
        resumes: '1',
        coverLetter: '0',
        resignationLetter: '0',
        docx: 'false',
        analyse: 'false',
        keyword: 'false',
        review: '0',
        proSample: 'false',
        unlock_templates: 'false',
        picture: 'false',
        ai_coverLetter: 'false',
        accessCover: 'false',
        download: 'false',
        credits: '0',
        ai_resume: 'false'
      },
      name: 'Rezi Free',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1591083965,
      plan: {
        id: 'plan_FfroNQtjGkZjYS',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 0,
        amount_decimal: '0',
        billing_scheme: 'per_unit',
        created: 1566541008,
        currency: 'usd',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'Monthly',
        product: 'prod_FfrohAFnsPcvLm',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    },
    {
      id: 'prod_Ffroe6Ht43gZNJ',
      object: 'product',
      active: true,
      attributes: [],
      created: 1566541005,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'plan',
        features: 'Unlimited Resumes,  1 Review Per Month',
        resumes: '9999999999',
        unlock_templates: 'true',
        picture: 'true',
        coverLetter: '9999999999',
        resignationLetter: '9999999999',
        docx: 'true',
        analyse: 'true',
        keyword: 'true',
        review: '1',
        proSample: 'true',
        ai_coverLetter: 'true',
        accessCover: 'true',
        download: 'true',
        credits: '100k',
        ai_resume: 'true'
      },
      name: 'Rezi Pro',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1591084321,
      plan: {
        id: 'plan_G4ad8l1Pv8oVcw',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 900,
        amount_decimal: '900',
        billing_scheme: 'per_unit',
        created: 1572243124,
        currency: 'usd',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'Monthly',
        product: 'prod_Ffroe6Ht43gZNJ',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    },
    {
      id: 'prod_HObrDlBxT4ASyP',
      object: 'product',
      active: true,
      attributes: [],
      created: 1591160139,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'plan_v1.1',
        resumes: '9999999999',
        coverLetter: '9999999999',
        resignationLetter: '9999999999',
        unlock_templates: 'true',
        picture: 'true',
        docx: 'true',
        analyse: 'true',
        keyword: 'true',
        review: '0',
        proSample: 'true',
        ai_coverLetter: 'false',
        accessCover: 'true',
        download: 'true',
        credits: '0',
        ai_resume: 'false'
      },
      name: 'Rezi Lifetime',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1591174400,
      plan: {
        id: 'price_1GpqZmEd6K02KvTIaMwLhFBC',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 0,
        amount_decimal: '0',
        billing_scheme: 'per_unit',
        created: 1591167590,
        currency: 'usd',
        interval: 'year',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: null,
        product: 'prod_HObrDlBxT4ASyP',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    },
    {
      id: 'prod_HOD3Trk7tq86Ao',
      object: 'product',
      active: true,
      attributes: [],
      created: 1591067865,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'plan_v1.1',
        resumes: '1',
        coverLetter: '0',
        resignationLetter: '0',
        docx: 'true',
        analyse: 'false',
        keyword: 'false',
        review: '0',
        proSample: 'false',
        unlock_templates: 'false',
        picture: 'false',
        ai_coverLetter: 'false',
        accessCover: 'false',
        download: 'true',
        credits: '0',
        ai_resume: 'false'
      },
      name: 'Rezi Basic - Quarterly',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1591170068,
      plan: {
        id: 'price_HOD32Nm8FThE3Q',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 600,
        amount_decimal: '600',
        billing_scheme: 'per_unit',
        created: 1591067866,
        currency: 'usd',
        interval: 'month',
        interval_count: 3,
        livemode: true,
        metadata: {},
        nickname: null,
        product: 'prod_HOD3Trk7tq86Ao',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    },
    {
      id: 'prod_HOD2oLyIlY3ISl',
      object: 'product',
      active: true,
      attributes: [],
      created: 1591067815,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'plan_v1.1',
        resumes: '1',
        coverLetter: '0',
        resignationLetter: '0',
        docx: 'true',
        analyse: 'false',
        keyword: 'false',
        review: '0',
        proSample: 'false',
        unlock_templates: 'false',
        picture: 'false',
        ai_coverLetter: 'false',
        accessCover: 'false',
        download: 'true',
        credits: '0',
        ai_resume: 'false'
      },
      name: 'Rezi Basic - Monthly',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1591083399,
      plan: {
        id: 'price_HOD2wWk5du5uz2',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 300,
        amount_decimal: '300',
        billing_scheme: 'per_unit',
        created: 1591067816,
        currency: 'usd',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: null,
        product: 'prod_HOD2oLyIlY3ISl',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    },
    {
      id: 'prod_Gkv3mbJHn5bWLs',
      object: 'product',
      active: true,
      attributes: [],
      created: 1582005964,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'plan_v1.1',
        resumes: '9999999999',
        coverLetter: '9999999999',
        resignationLetter: '9999999999',
        docx: 'true',
        analyse: 'true',
        keyword: 'true',
        review: '1',
        proSample: 'true',
        unlock_templates: 'true',
        picture: 'true',
        ai_writer: 'pro',
        ai_coverLetter: 'true',
        accessCover: 'true',
        download: 'true',
        credits: '100k',
        ai_resume: 'true'
      },
      name: 'Rezi Pro - Quarterly',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1591167370,
      plan: {
        id: 'plan_Gm1lqXelCF8zLY',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 8700,
        amount_decimal: '8700',
        billing_scheme: 'per_unit',
        created: 1582261520,
        currency: 'usd',
        interval: 'month',
        interval_count: 3,
        livemode: true,
        metadata: {},
        nickname: 'Rezi Pro - Quarterly',
        product: 'prod_Gkv3mbJHn5bWLs',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    },
    {
      id: 'prod_Gkv2kacUL4E1br',
      object: 'product',
      active: true,
      attributes: [],
      created: 1582005906,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'plan_v1.1',
        resumes: '9999999999',
        coverLetter: '9999999999',
        resignationLetter: '9999999999',
        unlock_templates: 'true',
        picture: 'true',
        docx: 'true',
        analyse: 'true',
        keyword: 'true',
        review: '1',
        proSample: 'true',
        ai_writer: 'pro',
        ai_coverLetter: 'true',
        accessCover: 'true',
        download: 'true',
        credits: '100k',
        ai_resume: 'true'
      },
      name: 'Rezi Pro - Monthly',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1591083598,
      plan: {
        id: 'plan_Gkv3ysaZTULusA',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 2900,
        amount_decimal: '2900',
        billing_scheme: 'per_unit',
        created: 1582005924,
        currency: 'usd',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'Rezi Pro - Monthly',
        product: 'prod_Gkv2kacUL4E1br',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    },
    {
      id: 'prod_Gkuz8WI1tg4uNu',
      object: 'product',
      active: true,
      attributes: [],
      created: 1582005686,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'plan_v1.1',
        resumes: '999999999',
        coverLetter: '999999999',
        resignationLetter: '9999999999',
        docx: 'true',
        analyse: 'false',
        keyword: 'false',
        review: '1',
        proSample: 'false',
        unlock_templates: 'true',
        picture: 'true',
        ai_coverLetter: 'true',
        accessCover: 'true',
        download: 'true',
        credits: '0',
        ai_resume: 'false'
      },
      name: 'Rezi Plus - Quarterly',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1591083764,
      plan: {
        id: 'plan_GkuzUFJiaQmxRX',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 2100,
        amount_decimal: '2100',
        billing_scheme: 'per_unit',
        created: 1582005714,
        currency: 'usd',
        interval: 'month',
        interval_count: 3,
        livemode: true,
        metadata: {},
        nickname: 'Rezi Plus - Quarterly',
        product: 'prod_Gkuz8WI1tg4uNu',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    },
    {
      id: 'prod_GkrOZum7e3Grf8',
      object: 'product',
      active: true,
      attributes: [],
      created: 1581992332,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        resumes: '999999999',
        features: 'Unlimited Resumes',
        unlock_templates: 'true',
        picture: 'true',
        type: 'plan_v1.1',
        coverLetter: '999999999',
        resignationLetter: '9999999999',
        docx: 'true',
        analyse: 'false',
        keyword: 'false',
        review: '1',
        proSample: 'false',
        ai_coverLetter: 'true',
        accessCover: 'true',
        download: 'true',
        credits: '0',
        ai_resume: 'false'
      },
      name: 'Rezi Plus - Monthly',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1591083823,
      plan: {
        id: 'plan_GkrPTfjPh1N0yZ',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 900,
        amount_decimal: '900',
        billing_scheme: 'per_unit',
        created: 1581992389,
        currency: 'usd',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'Rezi Plus Monthly',
        product: 'prod_GkrOZum7e3Grf8',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    }
  ],
  planslifetime: [
    {
      id: 'prod_GGBWkTZPVR1NaL',
      object: 'product',
      active: true,
      attributes: [],
      created: 1574917391,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'plan-life-time',
        resumes: '9999999999',
        unlock_templates: 'true',
        picture: 'true',
        coverLetter: '9999999999',
        resignationLetter: '9999999999',
        docx: 'true',
        analyse: 'true',
        keyword: 'true',
        review: '1',
        proSample: 'true',
        features: 'Unlimited Resumes',
        ai_coverLetter: 'false',
        accessCover: 'true'
      },
      name: 'Rezi Pro Life-time',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1587366364,
      plan: {
        id: 'plan_GGBXUEEMKSjGUn',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 0,
        amount_decimal: '0',
        billing_scheme: 'per_unit',
        created: 1574917446,
        currency: 'usd',
        interval: 'year',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'Life-time',
        product: 'prod_GGBWkTZPVR1NaL',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    }
  ],
  simpleplanslifetime: [
    {
      id: 'prod_H8A2RqRTXRQMRW',
      object: 'product',
      active: true,
      attributes: [],
      created: 1587366411,
      description: null,
      images: [],
      livemode: true,
      metadata: {
        type: 'simple-life-time',
        resumes: '9999999999',
        unlock_templates: 'true',
        picture: 'true',
        coverLetter: '9999999999',
        resignationLetter: '9999999999',
        docx: 'true',
        analyse: 'true',
        keyword: 'true',
        review: '0',
        proSample: 'true',
        features: 'Unlimited Resumes',
        ai_coverLetter: 'false',
        accessCover: 'true'
      },
      name: 'Simple Rezi Pro Life-time',
      statement_descriptor: null,
      type: 'service',
      unit_label: null,
      updated: 1587448844,
      plan: {
        id: 'plan_H8A3goj44Vv3Hu',
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 0,
        amount_decimal: '0',
        billing_scheme: 'per_unit',
        created: 1587366453,
        currency: 'usd',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'Simple Rezi Pro Life-time',
        product: 'prod_H8A2RqRTXRQMRW',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed'
      }
    }
  ],
  stripePromise: loadStripe(process.env.STRIPE_PUBLIC_KEY),
  customer: {},
  highlight: '',
  hoverFeature: '',
  isPro: null,
  customerPlan: null,
  customerIsGuest: null,
  openGuestModal: null,
  openUpgradeModal: false,
  partner: null,
  forceCoupon: null,
  forceProduct: null,
  forceBilling: null,
  loading: false,
  pastDue: false,
  productIndex(customer) {
    return checkSubProduct(customer);
  },
  async refreshCustomer(customerId = null) {
    const id = customerId || this.customer.id;
    this.customer = await fetchCustomer(id);
    this.loading = true;
    await this.updateUpgradeInfo(this.customer);
    this.loading = false;
    return this.customer;
  },

  async updateCustomer(data) {
    try {
      await window.fetch(`${FUNCTIONS_ENDPOINT}/stripe/customer/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: this.customer.id,
          data
        })
      });
      return this.refreshCustomer();
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async updateUpgradeInfo(customer) {
    if (!this.customerIsGuest) {
      const userPlan = this.plans.find(({ id }) => id === customer.plan);
      const userPlanLifeTime = this.planslifetime
        ? this.planslifetime.find(plan => plan.id === customer.plan)
        : null;
      const userSimplePlanLifeTime = this.simpleplanslifetime
        ? this.simpleplanslifetime.find(plan => plan.id === customer.plan)
        : null;
      this.customerPlan = userPlan
        ? userPlan
        : userPlanLifeTime
        ? userPlanLifeTime
        : userSimplePlanLifeTime
        ? userSimplePlanLifeTime
        : null;

      this.isPro =
        this.customerPlan && this.customerPlan.id
          ? this.customerPlan.id === 'prod_Gkv2kacUL4E1br' ||
            this.customerPlan.id === 'prod_Gkv3mbJHn5bWLs'
          : false;
    }
  },
  async init() {
    onAuthStateChanged(auth, async user => {
      if (user) {
        this.loading = true;
        const { user: userData } = new User(true);

        userData.path = `user/${user.uid}`;
        await userData.ready();
        if (
          userData.data.authProvider !== 'anonymous' &&
          userData.data.billing &&
          userData.data.billing.stripeId
        ) {
          this.customer = await fetchCustomer(
            userData.data.billing.stripeId,
            user.uid
          );
          if (!!Sentry && this.customer && this.customer.plan) {
            Sentry.setContext('user_plan_info', {
              plan: this.customer.plan
            });
          }
        }
        this.customerIsGuest = user.isAnonymous;
        await this.updateUpgradeInfo(this.customer);
        this.loading = false;
        const couponFromURL = getCookie('rezicoupon');
        this.checkCoupon(couponFromURL).then(coupon => {
          if (coupon) {
            this.forceCoupon = coupon;
          }
        });
      } else {
        this.customer = {};
      }
    });
  },
  async deleteSource(id) {
    try {
      await window.fetch(`${FUNCTIONS_ENDPOINT}/stripe/source/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customer: this.customer.id,
          source: id
        })
      });

      return this.refreshCustomer();
    } catch (err) {
      console.error(err);
    }
  },
  async updateSource(id, data) {
    try {
      await window.fetch(`${FUNCTIONS_ENDPOINT}/stripe/source/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id, data })
      });

      return this.refreshCustomer();
    } catch (err) {
      console.error(err);
    }
  },

  closeModal() {
    this.openGuestModal = false;
    this.openUpgradeModal = false;
    if (!this.customerIsGuest) {
      this.refreshCustomer();
    }
  },
  waitForLoading(resolve, reject, loading, customer) {
    if (!loading && this.customer && this.customer.plan) resolve(true);
    else setTimeout(this.waitForLoading.bind(this, resolve, reject), 30);
  },
  ensureCustomerIsSet() {
    return new Promise((resolve, reject) =>
      this.waitForLoading(resolve, reject, this.loading, this.customer)
    );
  },
  async checkCoupon(couponId) {
    if (couponId) {
      try {
        const getCoupon = await window
          .fetch(`${FUNCTIONS_ENDPOINT}/stripe/coupon`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              coupon: couponId
            })
          })
          .then(res => res.json());

        return getCoupon;
      } catch (err) {
        return null;
      }
    }
  },
  handleHoverFeature(feat) {
    this.hoverFeature = feat;
  },
  async needUpgrade(
    features = '',
    featuresInfos = null,
    isForced = false,
    anonymousAccess = false,
    partner = null,
    forceCoupon = null,
    forceProduct = null,
    forceBilling = null
  ) {
    if (features === 'credits') {
      const data = {
        plan_id: this.customerPlan && this.customerPlan.id,
        plan_name: this.customerPlan && this.customerPlan.name
      };

      trackingSendgrid('update_contact', data);
    }
    if (!this.customerIsGuest) {
      await this.ensureCustomerIsSet();
    }

    this.partner = partner;
    if (forceCoupon) {
      this.forceCoupon =
        typeof forceCoupon === 'string'
          ? await this.checkCoupon(forceCoupon)
          : forceCoupon;
    }
    this.forceProduct = forceProduct;
    this.forceBilling = forceBilling;
    this.isForced = false;
    if (!anonymousAccess && this.customerIsGuest) {
      this.openGuestModal = this.customerIsGuest;
      this.isForced = true;
      return true;
    } else {
      this.highlight = features;
      if (
        features === 'accessCover' &&
        this.customerPlan &&
        this.customerPlan.metadata[features] === 'true'
      ) {
        return false;
      } else if (
        features === 'resumes' &&
        ((this.customerPlan &&
          Number(this.customerPlan.metadata[features]) > featuresInfos) ||
          (this.customerIsGuest && featuresInfos < 1))
      ) {
        return false;
      } else if (
        features === 'accessCover' &&
        this.customerPlan &&
        this.customerPlan.metadata[features] === 'true' &&
        this.customerPlan.metadata.coverLetter !== '0'
      ) {
        return false;
      } else if (features === 'coverLetterAutoCreation' && this.customerPlan) {
        return !(
          Number(this.customerPlan.metadata.coverLetter) > featuresInfos
        );
      } else if (
        features === 'coverLetter' &&
        this.customerPlan &&
        Number(this.customerPlan.metadata[features]) > featuresInfos
      ) {
        return false;
      } else if (
        features === 'resignationLetter' &&
        this.customerPlan &&
        Number(this.customerPlan.metadata[features]) > featuresInfos
      ) {
        return false;
      } else if (features === 'cta' || features === 'profile') {
        this.openGuestModal = this.customerIsGuest;
        this.isForced = true;
        return true;
      } else if (
        features === 'proSample' &&
        this.customerPlan &&
        this.customerPlan.metadata.proSample === 'true'
      ) {
        return false;
      } else if (
        features === 'pdf' &&
        !this.customerIsGuest &&
        this.customerPlan &&
        this.customerPlan.metadata.docx === 'true'
      ) {
        return false;
      } else if (
        features === 'pdf' &&
        !this.customerIsGuest &&
        this.customerPlan &&
        this.customerPlan.metadata.docx === 'false'
      ) {
        return featuresInfos < 1; //Need more than 1 download
      } else if (
        features === 'docx' &&
        this.customerPlan &&
        this.customerPlan.metadata[features] === 'true'
      ) {
        return false;
      } else if (
        features === 'picture' &&
        this.customerPlan &&
        this.customerPlan.metadata[features] === 'true'
      ) {
        return false;
      } else if (
        features === 'unlock_templates' &&
        this.customerPlan &&
        ((featuresInfos === 'alpha-template' &&
          this.customerPlan.metadata.unlock_templates === 'true' &&
          this.customerPlan.metadata.analyse === 'true') ||
          (featuresInfos !== 'alpha-template' &&
            this.customerPlan.metadata.unlock_templates === 'true'))
      ) {
        return false;
      } else {
        this.openGuestModal = this.customerIsGuest;
        this.openUpgradeModal = !this.customerIsGuest;
        this.isForced = isForced;
        return true;
      }
    }
  },

  getUserTemplates(user) {
    const userPlan = this.plans.find(plan => plan.id === this.customer.plan),
      allUnlocked = userPlan && userPlan.metadata.unlock_templates;

    return this.templates
      .map(({ id, ...props }) => ({
        id,
        unlocked: allUnlocked || user.data.billing.templates.includes(id),
        ...props
      }))
      .slice()
      .sort((a, b) => b.unlocked - a.unlocked);
  }
});

store.init();
export default createContext(store);
