import { Input } from '../../Form';
import { observer } from 'mobx-react-lite';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import './styles.module.css';
import { useTranslation } from 'react-i18next';
import { FUNCTIONS_ENDPOINT } from '../../../lib/constants';
import { notify } from '../../Notification';
import debounce from '../../../utils/debounce';
import Loading from '../../Loading';
import { subscribeLifeTime } from '../PriceDisplay/action';
import RmsStore from '../../../stores/Rms';
import Billing from '../../../stores/Billing';
import Users from '../../../stores/User';
import { trackingProductEvent } from '../../../utils/tracking';

export default observer(Coupon);
function Coupon({ forceCoupon, openCoupon, handleCoupon, handleUpgrade }) {
  const [state, setState] = useState({
    coupon: forceCoupon ? { id: forceCoupon } : {},
    openCoupon: openCoupon || false,
    isLoading: false
  });
  const { customer } = useContext(Billing);
  const { user } = useContext(Users);
  const { store } = useContext(RmsStore);
  const { t } = useTranslation();

  const debounceCallback = useCallback(
    debounce((state, customer, user, store) => {
      checkCoupon(state, customer, user, store);
    }, 2000),
    []
  );

  function checkCouponExpired(coupon) {
    if (!coupon.valid) {
      return true;
    }
    if (
      coupon.max_redemptions &&
      coupon.times_redeemed === coupon.max_redemptions
    ) {
      return true;
    }
  }

  async function checkCoupon(state, customer, user, store) {
    if (state.coupon && state.coupon.id) {
      try {
        const coupon = await window
          .fetch(`${FUNCTIONS_ENDPOINT}/stripe/coupon`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              coupon: state.coupon.id
            })
          })
          .then(res => res.json());
        const couponChecked = {
          ...coupon,
          description: checkCouponExpired(coupon)
            ? t('modal_plan_checkout.expired_coupon')
            : ''
        };
        if (checkCouponExpired(coupon)) {
          trackingProductEvent('couponFailed', {
            reason: 'expired',
            coupon: state.coupon.id
          });
        } else {
          trackingProductEvent('couponSuccess', {
            coupon: state.coupon.id
          });
        }

        setState({
          ...state,
          isLoading: false,
          coupon: couponChecked
        });
        handleCoupon(couponChecked);
      } catch (err) {
        subscribeLifeTime(state.coupon.id, customer, user, store)
          .then(async res => {
            if (res === 'need unlock') {
              await handleUpgrade();
              setState({
                ...state,
                isLoading: false
              });
              trackingProductEvent('lifetimeSuccess');
            } else {
              setState({
                ...state,
                isLoading: false
              });
              trackingProductEvent('couponFailed', {
                reason: 'invalid',
                coupon: state.coupon.id
              });
              notify(t('modal_plan_checkout.error_coupon'));
              handleCoupon({});
            }
          })
          .catch(() => {
            setState({
              ...state,
              isLoading: false
            });
            trackingProductEvent('couponFailed', {
              reason: 'error',
              coupon: state.coupon.id
            });
            notify(t('modal_plan_checkout.error_coupon'));
            handleCoupon({});
          });
      }
    }
  }

  useEffect(() => {
    if (forceCoupon && customer.id) {
      checkCoupon({ coupon: { id: forceCoupon } }, customer, user, store);
    }
  }, [forceCoupon, customer.id]);

  function handleCouponChange(value) {
    handleCoupon({});
    setState({
      ...state,
      isLoading: true,
      coupon: { id: value }
    });
    debounceCallback(
      { ...state, coupon: { id: value } },
      customer,
      user,
      store
    );
  }

  return (
    <div styleName="checkoutForm-coupon">
      {forceCoupon || state.openCoupon ? (
        <>
          <Input
            placeholder={t('modal_plan_checkout.label_coupon')}
            value={(state.coupon && state.coupon.id) || ''}
            onChange={({ target }) => handleCouponChange(target.value)}
          />
          {state.coupon.id && (
            <div styleName="coupon">
              {!!state.coupon.description && (
                <span
                  styleName="coupon-result"
                  className={state.coupon.doubleCheck ? '' : 'alert'}
                >
                  {state.coupon.description}
                </span>
              )}
            </div>
          )}
          {state.coupon && state.coupon.id && state.isLoading && (
            <Loading
              stylename="small-btn"
              maxHeight="1em"
              minHeight="1em"
              color="var(--color-primary)"
              style={{
                justifyContent: 'flex-end',
                margin: '-47px 19px 0px 0px',
                display: 'block',
                float: 'right'
              }}
            />
          )}
        </>
      ) : (
        <div
          styleName="coupon-link"
          onClick={() => setState({ ...state, openCoupon: true })}
        >
          {t('modal_plan_payment.coupon_redemption')}
        </div>
      )}
    </div>
  );
}
