import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import User from '../../stores/User';
import { navigate, Redirect } from '@reach/router';
import { auth } from '../../lib/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import lsTest from '../../utils/lsAvailable';

/**
 * Route component
 * Route wrapper with redirects and lazy loading
 * @property {boolean} isPrivate
 * @property {boolean} allowAnonymous
 * @property {string} Component
 * @property {string} path
 */
export default observer(Route);
function Route({
  isPrivate = false,
  isPublic = false,
  allowAnonymous = true,
  Component = 'div',
  path = '',
  className = '',
  title = 'Resume builder',
  rmsElement = {},
  ...props
}) {
  const { user } = useContext(User);
  const { path: authenticated } = user;
  const isAnonymous = user.data.authProvider === 'anonymous';
  let userEmailVerify = user && user.emailVerified;
  onAuthStateChanged(auth, user => {
    userEmailVerify = user && user.emailVerified;
  });

  const isUnverified =
    !(user.data.authProvider === 'customToken') &&
    user &&
    user.emailVerified === false &&
    !isAnonymous;

  const history =
    !authenticated && lsTest() === true
      ? window.localStorage.getItem('rh-hist')
      : null;

  if (!authenticated) {
    if (
      lsTest() === true &&
      !(
        history &&
        (history.split('///')[1] === window.location.pathname ||
          (history === '/login' && window.location.pathname === '/login'))
      )
    ) {
      window.localStorage.setItem(
        'rh-hist',
        history
          ? history.split('///')[history.split('///').length - 1] +
              '///' +
              window.location.pathname
          : window.location.pathname
      );
    }
  }

  const historyNavigate = async () => {
    const history =
      lsTest() === true ? await window.localStorage.getItem('rh-hist') : null;
    if (
      history &&
      history.split('///')[0] &&
      !history.split('///')[0].includes('confirm-email') &&
      !history.split('///')[0].includes('login')
    ) {
      const redirect =
        history.split('///')[0][0] !== '/'
          ? '/' + history.split('///')[0]
          : history.split('///')[0];
      return redirect[0] !== '/'
        ? '/' + redirect
        : redirect[0] === '/' && redirect[1] === '/'
        ? redirect.substring(1, redirect.length - 1)
        : redirect;
    } else {
      return '/';
    }
  };

  useEffect(() => {
    let mounted = true;
    const history =
      lsTest() === true ? window.localStorage.getItem('rh-hist') : null;
    if (authenticated && history) {
      setTimeout(() => {
        if (mounted && lsTest() === true) {
          window.localStorage.removeItem('rh-hist');
        }
      }, 1000);
    }
    return () => (mounted = false);
  }, [authenticated]);

  // if (isPublic && !isPrivate && path !== 'confirm-email') {
  //   return (
  //     <Component path={path} title={title} rmsElement={rmsElement} {...props} />
  //   );
  // }

  if (isPrivate && !authenticated) {
    navigate('/login');
  } else if (!allowAnonymous && isAnonymous) {
    navigate('/');
  } else if (
    user.path &&
    isPrivate &&
    isUnverified &&
    !userEmailVerify &&
    user.data.authProvider !== undefined &&
    user.data.authProvider !== 'anonymous' &&
    user.data.authProvider.length > 0
  ) {
    return <Redirect from={path} to="/confirm-email" noThrow />;
  } else if (
    !isPrivate &&
    authenticated &&
    path !== 'confirm-email' &&
    path !== 's/:shareId'
  ) {
    historyNavigate(path).then(newPath => {
      navigate(newPath[0] !== '/' ? '/' + newPath : newPath);
    });
  } else if (userEmailVerify && path === 'confirm-email') {
    navigate('/dashboard');
  } else {
    return (
      <Component path={path} title={title} rmsElement={rmsElement} {...props} />
    );
  }
}
