import React, { useContext } from 'react';
import './styles.module.css';
import AuthForm from '../AuthForm';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';

/**
 * GuestSignup component
 * Guest signup upsell modal
 * @property {string} title
 * @property {string} description
 */
export default function GuestSignup({
    title = '',
    className,
    ...attrs
  }) {
  const {t} = useTranslation();
  return (
    <Modal className={className || ''} {...attrs}>
      <AuthForm type="signup" title={(title === '') ? t('sidebar.cta_guest_signup') : title} noGuest />
    </Modal>
  );
}
