import React from 'react';
import './styles.module.css';
import Loading from '../Loading';
export default function SplashScreen() {
  return (
    <div styleName="splash">
      <Loading color="#ccc" />
    </div>
  );
}
