import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './containers/App';
import './i18n';
import ErrorBoundary from './hoc/ErrorBoundary';
if (process.env.GENERAL_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://b5a810718bc948e0892a718539ab0a82@o912797.ingest.sentry.io/5850139',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const AppWithErrorBoundary = () => {
  const Component = (
    // <ErrorBoundary>
    <App />
    // </ErrorBoundary>
  );
  return Component;
};

ReactDOM.render(<AppWithErrorBoundary />, document.querySelector('#root'));
