import React, { forwardRef, useEffect, useState } from 'react';
import snarkdown from 'snarkdown';
import './styles.module.css';
import Switch from '../../Switch';
import { useTranslation } from 'react-i18next';
import lsTest from '../../../utils/lsAvailable';

function randomId() {
  return Math.random()
    .toString(36)
    .substring(7);
}

/**
 * Input field wrapper
 * @property {string} label Label for input
 * @property {object} Input Input element for the field
 * @property {boolean} required Mark input as required
 * @property {object} validation Config for success/error messages
 * @property {function} onChange Change callback, passed to input
 */
const Field = forwardRef(
  (
    {
      label = '',
      InputElement = 'input',
      required = false,
      validation = null,
      className,
      inputClassName,
      children,
      switchId = '',
      switchable = false,
      switchData = true,
      switchClassName = 'hide',
      onSwitch = () => {},
      isdisabled = false,
      autocompleteGpt = false,
      haveAutocompletion = false,
      id,
      ...attrs
    },
    ref
  ) => {
    const { t } = useTranslation();
    const syncedId = randomId();
    const [suggestOn, setSuggestOn] = useState(true);

    function onSwitchSuggest(e) {
      if (e.target.checked) {
        if (history.pushState) {
          if (lsTest() === true) {
            localStorage.setItem('no-suggest', '1');
          }
          setSuggestOn(false);
        }
      } else {
        if (history.pushState) {
          if (lsTest() === true) localStorage.removeItem('no-suggest');
          setSuggestOn(true);
        }
      }
    }

    useEffect(() => {
      const urlParams =
        lsTest() === true ? window.localStorage.getItem('no-suggest') : null;
      if (urlParams && urlParams === '1') {
        setSuggestOn(false);
      } else {
        setSuggestOn(true);
      }
    }, []);

    return (
      <div className={className || ''}>
        {!!label && (
          <label className="label" styleName="label" htmlFor={id || syncedId}>
            <span
              dangerouslySetInnerHTML={{
                __html: snarkdown(label)
              }}
            />
            {required ? ' *' : ''}
            {switchable && (
              <span styleName="switchable" data-visible={switchClassName}>
                <span>{t('generator.show_on_resume')}</span>
                <Switch
                  id={switchId}
                  checked={switchData}
                  onClick={e => e.stopPropagation()}
                  onChange={e => onSwitch(e)}
                />
              </span>
            )}
          </label>
        )}
        <div>
          {className === 'resume-review-section' ? (
            <InputElement
              ref={ref}
              className={`inputEl ${inputClassName}`}
              styleName="inputEl"
              id={id || syncedId}
              required={required}
              isDisabled={isdisabled}
              aria-label={!!label ? label : 'Form field'}
              style={autocompleteGpt ? { background: 'transparent' } : {}}
              {...attrs}
            >
              {children}
            </InputElement>
          ) : (
            <InputElement
              ref={ref}
              className={`inputEl ${inputClassName}`}
              styleName="inputEl"
              id={id || syncedId}
              required={required}
              aria-label={!!label ? label : 'Form field'}
              style={autocompleteGpt ? { background: 'transparent' } : {}}
              {...attrs}
            >
              {children}
            </InputElement>
          )}
        </div>
        {!!validation && (
          <span
            styleName="status"
            data-valid-message={validation.valid}
            data-invalid-message={validation.invalid}
          />
        )}
      </div>
    );
  }
);
export default Field;
